/*!
 * bit.base.jquery.scrollablelist.js
 *
 */

jQuery.fn.scrollableList = function() {

    var isLeftRight = !$(this).hasClass("vertical"),
        startDirection = isLeftRight ? "left" : "top",
        endDirection = isLeftRight ? "right" : "bottom",
        startMarginCssAttr = "margin-" + startDirection,
        endMarginCssAttr = "margin-" + endDirection,
        startControlClass = "scroll-" + startDirection,
        endControlClass = "scroll-" + endDirection,
        startPaddingCssAttr = "padding-" + startDirection,
        endPaddingCssAttr = "padding-" + endDirection,
        startControlSelector = "." + startControlClass,
        endControlSelector = "." + endControlClass,
        mainAxis = isLeftRight ? "width" : "height",
        secondaryAxis = isLeftRight ? "height" : "width",
        activeClass = "active",
        inactiveClass = "inactive";

    return this.each(function () {

        var container = $(this),
            list = container.find('ul'),
            thumbnailsWrapper = $(this).find(".thumbnails-wrapper"),
            maxDimension = 0,
            overallListDimension = 0;

        // TODO: PAJ we are now waiting for the editor container to be visible before proceeding - this code may be
        // overly defensive now.  (It shouldn't get called anymore.)
        var listIsVisible = list.is(":visible");

		list.children('li').each(function() {

            var itemDimension;

            if (listIsVisible){
                var marginStartDirection = parseInt($(this).css(startMarginCssAttr));
                var marginEndDirection = parseInt($(this).css(endMarginCssAttr));
                var baseItemDimension = isLeftRight ? $(this).outerWidth() : $(this).outerHeight();
                itemDimension = baseItemDimension + marginStartDirection + marginEndDirection;
            } else {
                // use estimated size when list isn't visible
                itemDimension = 109;
            }

			overallListDimension += itemDimension;
			
			if (itemDimension > maxDimension) maxDimension = itemDimension;
		});

		list.css(mainAxis, overallListDimension);
		
		var scrollDimension = 2 * maxDimension;

        var needsScroll = isLeftRight ?
            list.outerWidth() > container.width() :
            list.outerHeight() > container.height();

		if (needsScroll) {

            container.css(startPaddingCssAttr, "20px").css(endPaddingCssAttr, "20px");

            // use estimated size when list isn't visible
            var controlLength = isLeftRight ? thumbnailsWrapper.height()-8 : (listIsVisible ? thumbnailsWrapper.width() : 90);

			var startControl = $("<span />");
            var animSpeed = 300;
			startControl.addClass(startControlClass);
			startControl.addClass(inactiveClass);
            startControl.css(secondaryAxis, controlLength);
			startControl.click(function() {
				var startDirectionMargin = parseInt(list.css(startMarginCssAttr));
				if (startDirectionMargin + 1.5 * scrollDimension < 0) {
                    var anim = {};
                    anim[startMarginCssAttr] = '+=' + scrollDimension;
					list.animate(anim, animSpeed);
					$(endControlSelector).removeClass(inactiveClass);
				}
				else {
					var anim= {};
                    anim[startMarginCssAttr] = 0;
                    list.animate(anim, animSpeed);
					$(startControlSelector).addClass(inactiveClass);
					$(endControlSelector).removeClass(inactiveClass);
				}
			});
			
			var endControl = $("<span />");
			endControl.addClass(endControlClass);
            endControl.css(secondaryAxis, controlLength);
			endControl.click(function() {
				var startMargin = parseInt(list.css(startMarginCssAttr));

                var needsScroll = isLeftRight ?
                    list.width() - container.width() > 1.5 * scrollDimension - startMargin :
                    list.height() - container.height() > 1.5 * scrollDimension - startMargin;

				if (needsScroll) {
					var anim = {};
                    anim[startMarginCssAttr] = '-=' + scrollDimension;
                    list.animate(anim, animSpeed);
					$(startControlSelector).removeClass(inactiveClass);
				}
				else {
					var anim = {};
                    anim[startMarginCssAttr] = isLeftRight ?
                        container.width() - list.width() :
                        container.height() - list.height();
                    list.animate(anim, animSpeed);
					$(endControlSelector).addClass(inactiveClass);
					$(startControlSelector).removeClass(inactiveClass);
				}
			});
			
			list.parent().before(startControl);
			list.parent().before(endControl);
		}
		
		list.find('a').click(function(e) {
			list.find('li').removeClass(activeClass);
			$(this).parents('li').addClass(activeClass);
		});

        // starts hidden
        $(this).css({visibility: "visible"});
		
	});
};


