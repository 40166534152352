// TODO: finally angularize remaining code and kill this file!
var prototype = {
    'config': {},
    init: function () {
        prototype.config = {
            'window': $(window),
            'dropdowns': $(".supermenu a, .search-results .dropdown, .recents")
        };

        // Used to set correct height of all modals... including wicket
        // register a window resize event to adjust the height of modals
        var throttledModalResize = _.throttle(prototype.setModalMaxHeights, 300);
        $(window).resize(throttledModalResize);
    },
    setModalMaxHeights: function setModalMaxHeights() {
        var windowHeight = $(window).height();
        $(".modal:visible").not('.modal-full').each(function () {
            var me = $(this),
                maxH = (windowHeight * .78) - 50;

            if (me.find('.modal-footer').length) {
                me.find('.modal-body').css('max-height', maxH - 30);
            } else {
                me.find('.modal-body').css('max-height', maxH);
            }
        });
    },
    closeDropdowns: function () {
        var cfg = prototype.config;
        if (cfg && cfg.dropdowns && cfg.dropdowns.length) {
            cfg.dropdowns.removeClass("open");
        }
    },
    getWindowHeight: function () {
        return prototype.config.window.height();
    }
};

