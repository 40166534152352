//bit.base/htmlboxdiagram.js
// activeBn is optional, for use when JS controls show/hide and interaction
function prepareHtmlBoxDiagram(id, activeBn){

    var container = $("#" + id);
    var boxes = container.find('.box');

    function handleMouseEnter ($elem, e){
        e.stopPropagation();
        container.find(".box.hover").removeClass("hover");
        $elem.addClass("hover");
    }

    function handleMouseLeave ($elem, e) {
        e.stopPropagation();
        $elem.removeClass("hover");
        $elem.find('.box').removeClass('hover');
        $elem.parent(".box").addClass("hover");
    }

    boxes
        .on("mouseenter", function(e){
            handleMouseEnter($(this), e);
        })
        .on("mouseleave", function(e){
            handleMouseLeave($(this), e);
        });

    function setChildHeight (children) {
        children = children.not('.expanded');
        var childHeight = 0, maxBoxHeight = 0;
        children.each(function (index, child) {
            var $child = $(child);
            childHeight = $child.outerHeight();
            maxBoxHeight =  childHeight > maxBoxHeight ? childHeight : maxBoxHeight;
        });
        children.css('height', maxBoxHeight);
    }

    // no active Bn means this is the global capabilities arch diagram
    if (!activeBn){

        // decorate children of active box
        boxes.find(".box.active .box").addClass("child-of-active");

        container.find('.box-diagram > .box').each(function (index, child) {
            setChildHeight($(child).find('> .box'));
        });


        // just show everything Wicket sent out
        container.findWithSelf(".box-diagram").show("fast");

        // whole boxes are clickable in global capability diagram
        boxes.on('click', function(e){
            e.stopPropagation();
            var href = $(this).find('> label > a').attr('href');
            window.location = href;
        });

    }  else {
        // recursive collapser / counter-upper
        var collapseChildren = function(parent){
            var children = parent.find('> .box');
            if (children.length > 0) {

                // hide the children behind a count
                children.hide();

                // child count
                var count = $('<div class="count">' + children.length + '</div>');
                parent.append(count);

                // sibling count (if this is in the active branch)
                if (children.length > 1) {
                    var text = String.format("@@Number_Others", (children.length - 1));
                    var siblingCount = $('<div class="siblings">' + text + '</div>');
                    parent.append(siblingCount);
                }

                // both count behaviors are the same
                count.add(siblingCount)
                    .on('click', function(e){
                        parent.addClass('expanded');
                        setChildHeight(children);
                        children.show('fast');
                        parent.find('> .count, > .siblings').hide();
                        e.stopPropagation();
                    })
                    .on('mouseenter', function(e){
                        handleMouseEnter($(this), e);
                    })
                    .on('mouseleave', function(e){
                        handleMouseLeave($(this), e);
                    });

                // add collapse control
                var collapse = $('<div class="collapse"></div>');
                parent.append(collapse);
                collapse.on('click', function(e){

                    // hide all children, show childCount
                    parent.find('> .box').hide('fast');
                    parent.find('> .count').show();
                    parent.removeClass('expanded');

                    e.stopPropagation();
                });

                // keep going down the tree
                children.each(function(){
                    collapseChildren($(this));
                });
            }
        };

        // treat each diagram (mult-parent) separately
        container.findWithSelf(".box-diagram").each(function(){

            // collapse everything and add counts
            collapseChildren($(this).find('> .box'));

            // find the active one, open it up
            var activeBox = $(this).find('.box[data-bn="' + activeBn + '"]');
            activeBox.addClass('active').show();

            // show children of active
            if (activeBox.find('> .box').length > 0) {  
                activeBox.find('> .box').show();
                activeBox.addClass('expanded');
            }
            activeBox.find('.box').addClass('child-of-active');


            // open ancestors, set up siblings
            activeBox.parentsUntil('.box-diagram').each(function(){
                $(this).addClass('expanded').show();
            });
            
            setChildHeight(activeBox.find('> .box'));

        }).show('fast');
    }
}
