var BnCodeToUrl = {};
BnCodeToUrl['01'] = 'actor';         // Actor
BnCodeToUrl['4G'] = '';              // Adapter Batch
BnCodeToUrl['4J'] = '';              // Adapter Property
BnCodeToUrl['24'] = 'report';        // Advanced Query
BnCodeToUrl['0O'] = '';              // Adapter
BnCodeToUrl['1S'] = '';              // Alias
BnCodeToUrl['0U'] = 'document';      // Asset
BnCodeToUrl['2Z'] = '';              // Asset Version
BnCodeToUrl['4A'] = 'scorecard';     // Audit aka Scorecard
BnCodeToUrl['45'] = '';              // Authorize User
BnCodeToUrl['0Q'] = 'capability';    // Capability
BnCodeToUrl['08'] = '';              // Connection Data Structure
BnCodeToUrl['09'] = '';              // Connection Format
BnCodeToUrl['0A'] = '';              // Connection Frequency
BnCodeToUrl['2O'] = 'workspace';     // Circle aka Workspace
BnCodeToUrl['2F'] = 'company';       // Company
BnCodeToUrl['07'] = 'connection';    // Connection
BnCodeToUrl['3Z'] = 'rule';          // Rule
BnCodeToUrl['40'] = '';              // Rule Rollup
BnCodeToUrl['3J'] = '';              // Content Asset
BnCodeToUrl['0C'] = '';              // Connection Transport
BnCodeToUrl['0F'] = '';              // Contact Type
BnCodeToUrl['0L'] = 'fieldedit';     // Custom Fields
BnCodeToUrl['44'] = 'datum';         // Data
BnCodeToUrl['39'] = 'demand';        // Demand
BnCodeToUrl['0I'] = 'fieldsetedit';  // Fieldsets
BnCodeToUrl['0T'] = '';              // Geography
BnCodeToUrl['3F'] = '';              // Invite
BnCodeToUrl['0V'] = '';              // Link
BnCodeToUrl['0W'] = '';              // Map
BnCodeToUrl['0T'] = 'market';        // Market
BnCodeToUrl['2K'] = '';              // Note
BnCodeToUrl['0K'] = 'optionlistedit';// Option List
BnCodeToUrl['0Y'] = 'organization';  // Organization
BnCodeToUrl['3C'] = '';              // Outline Item
BnCodeToUrl['3O'] = '';              // Participation
BnCodeToUrl['0E'] = 'profile';       // Person Profile
BnCodeToUrl['4L'] = 'deployment';    // Physical / Deployment
BnCodeToUrl['26'] = '';              // Position
BnCodeToUrl['0Z'] = 'product';       // Product
BnCodeToUrl['15'] = 'skill';         // Skill
BnCodeToUrl['16'] = 'standard';      // Standard
BnCodeToUrl['0G'] = 'strategy';      // Strategy
BnCodeToUrl['18'] = 'system';        // System
BnCodeToUrl['1F'] = 'tag';           // Tag
BnCodeToUrl['4O'] = 'task';          // Task
BnCodeToUrl['1H'] = 'technology';    // Technology
BnCodeToUrl['1K'] = 'tenant';        // Tenant
BnCodeToUrl['02'] = 'tenantendpoint';// Tenant Endpoint
BnCodeToUrl['2P'] = 'discussions';   // Discussin/Topic
BnCodeToUrl['06'] = 'worksheet';     // Worksheet
BnCodeToUrl['1L'] = 'customdashboard';     // Custom dashboards
BnCodeToUrl['4M'] = 'worksheetview';     // Worksheet view

var EntityBnCodeToIconClass = {};
EntityBnCodeToIconClass['01'] = 'actor';            // Actor
EntityBnCodeToIconClass['24'] = 'report';           // Report
EntityBnCodeToIconClass['0U'] = 'document';         // Asset
EntityBnCodeToIconClass['4A'] = 'audit';            // Audit
EntityBnCodeToIconClass['0Q'] = 'capability';       // Capability
EntityBnCodeToIconClass['07'] = 'connection';       // Connection
EntityBnCodeToIconClass['2O'] = 'circle';           // Circle
EntityBnCodeToIconClass['2F'] = 'company';          // Company
EntityBnCodeToIconClass['3Z'] = 'compliance';       // Rule
EntityBnCodeToIconClass['39'] = 'demand';           // Demand
EntityBnCodeToIconClass['0T'] = 'market';           // Market
EntityBnCodeToIconClass['0Y'] = 'organization';     // Organization
EntityBnCodeToIconClass['0E'] = 'people';           // People
EntityBnCodeToIconClass['39'] = 'demand';           // Demand
EntityBnCodeToIconClass['15'] = 'skill';            // Skill
EntityBnCodeToIconClass['16'] = 'standard';         // Standard
EntityBnCodeToIconClass['0G'] = 'strategy';         // Standard
EntityBnCodeToIconClass['1F'] = 'tag';              // Tag
EntityBnCodeToIconClass['18'] = 'system';           // System
EntityBnCodeToIconClass['44'] = 'data';             // Data
EntityBnCodeToIconClass['1H'] = 'technology';       // Technology
EntityBnCodeToIconClass['4L'] = 'physical';         // Physical
EntityBnCodeToIconClass['0Z'] = 'product';          // Product
EntityBnCodeToIconClass['0K'] = 'gear';             // Option List
EntityBnCodeToIconClass['02'] = 'gear';             // Tenant Endpoint
EntityBnCodeToIconClass['06'] = 'worksheet';        // Worksheet
EntityBnCodeToIconClass['4M'] = 'worksheet';        // Worksheet
EntityBnCodeToIconClass['1L'] = 'dashboard';

var InsightTypeBnsToIconClass = {};
InsightTypeBnsToIconClass['044S00000001'] = 'bubble';
InsightTypeBnsToIconClass['044S00000002'] = 'circle';
InsightTypeBnsToIconClass['044S00000003'] = 'matrix';
InsightTypeBnsToIconClass['044S00000004'] = 'dashboard';
InsightTypeBnsToIconClass['04430000000X'] = 'document';
InsightTypeBnsToIconClass['044S00000006'] = 'calculator';
InsightTypeBnsToIconClass['044S00000007'] = 'roadmap';
InsightTypeBnsToIconClass['044S0000000J'] = 'table';
InsightTypeBnsToIconClass['044S00000008'] = 'connection';
InsightTypeBnsToIconClass['044S00000009'] = 'relational';
InsightTypeBnsToIconClass['044S0000000B'] = 'report';
InsightTypeBnsToIconClass['044S0000000C'] = 'compliance';
InsightTypeBnsToIconClass['044S0000000D'] = 'audit';
InsightTypeBnsToIconClass['04430000000J'] = 'tag';
InsightTypeBnsToIconClass['044S0000000K'] = 'roadmap';

var EntityUtils = {};

// just a list of class names (so they aren't repeated)
EntityUtils.icons = {
    activity: 'icon-activity',
    actor: 'icon-actor',
    audit: 'icon-audit',
    architecture: 'icon-architecture',
    bubble: 'icon-bubble',
    capability: 'icon-capability',
    calculator: 'icon-calculator',
    calendar: 'icon-calendar',
    circle: 'icon-circle',
    company: 'icon-company',
    compliance: 'icon-compliance',
    composite: 'icon-composite',
    connection: 'icon-connection',
    connectionSub: 'icon-connection-sub',
    customFields: 'icon-custom-fields',
    dashboard: 'icon-dashboard',
    data: 'icon-data',
    database: 'icon-database',
    demand: 'icon-demand',
    discussion: 'icon-discussion',
    document: 'icon-document',
    extract: 'icon-extract',
    fieldset: 'icon-fieldset',
    fieldsetLayout: 'icon-fieldset-layout',
    gear: 'icon-gear',
    geography: 'icon-geography',
    insight: 'icon-eye',
    market: 'icon-market',
    matrix: 'icon-matrix',
    news: 'icon-news',
    notes: 'icon-help',  // TODO: pick icon
    organization: 'icon-organization',
    people: 'icon-people',
    physical: 'icon-physical',
    product: 'icon-product',
    profile: 'icon-profile',
    relational: 'icon-relational',
    roadmap: 'icon-roadmap',
    report: 'icon-report',
    scorecard: 'icon-audit',
    search: 'icon-search',
    skill: 'icon-skill',
    standard: 'icon-standard',
    strategy: 'icon-strategy',
    system: 'icon-system',
    table: 'icon-table', //TODO: what section is this for?
    tag: 'icon-tag',
    tasks: 'icon-check-alt',
    technology: 'icon-technology',
    tenantEndpoint: 'icon-technology', //TODO
    workspace: 'icon-circle',
    worksheet: 'icon-worksheet',
    worksheets: 'icon-worksheet',
    unknown: 'icon-barometer-dots'
};

// TODO: shouldn't need this for the new icon impl
EntityUtils.getIconName = function (iconName) {
    return EntityUtils.icons[iconName].replace('icon-', '');
};
EntityUtils.systemTypeBnToIcon = {
    '041F00000003': EntityUtils.icons.gear,         // Batch Job
    '041F0000000A': EntityUtils.icons.composite,    // Composite
    '041F00000000': EntityUtils.icons.system,       // Custom Application
    '041F00000001': EntityUtils.icons.database,     // Custom Database
    '041F00000002': EntityUtils.icons.system,       // Infrastructure
    '041F00000005': EntityUtils.icons.system,       // Modified Packaged Application
    '041F00000004': EntityUtils.icons.gear,         // Module
    '041F00000006': EntityUtils.icons.system,       // Package Application
    '041F00000007': EntityUtils.icons.database,     // Package Database
    '041F00000008': EntityUtils.icons.gear          // Process
};


var PageIdentifierToEntityTypeCode = {};
PageIdentifierToEntityTypeCode['actor'] = 'ACT';
PageIdentifierToEntityTypeCode['actors'] = 'ACT';
PageIdentifierToEntityTypeCode['report'] = 'ADQ';
PageIdentifierToEntityTypeCode['reports'] = 'ADQ';
PageIdentifierToEntityTypeCode['document'] = 'AST';
PageIdentifierToEntityTypeCode['documents'] = 'AST';
PageIdentifierToEntityTypeCode['scorecard'] = 'AUD';
PageIdentifierToEntityTypeCode['scorecards'] = 'AUD';
PageIdentifierToEntityTypeCode['capability'] = 'CAP';
PageIdentifierToEntityTypeCode['capabilities'] = 'CAP';
PageIdentifierToEntityTypeCode['circle'] = 'CIR';
PageIdentifierToEntityTypeCode['circles'] = 'CIR';
PageIdentifierToEntityTypeCode['company'] = 'COM';
PageIdentifierToEntityTypeCode['companies'] = 'COM';
PageIdentifierToEntityTypeCode['companies-inuse'] = 'COM';
PageIdentifierToEntityTypeCode['connection'] = 'CON';
PageIdentifierToEntityTypeCode['connections'] = 'CON';
PageIdentifierToEntityTypeCode['rule'] = 'CRL';
PageIdentifierToEntityTypeCode['rules'] = 'CRL';
PageIdentifierToEntityTypeCode['datum'] = 'DAT';
PageIdentifierToEntityTypeCode['data'] = 'DAT';
PageIdentifierToEntityTypeCode['demand'] = 'DEM';
PageIdentifierToEntityTypeCode['demands'] = 'DEM';
PageIdentifierToEntityTypeCode['market'] = 'MKT';
PageIdentifierToEntityTypeCode['markets'] = 'MKT';
PageIdentifierToEntityTypeCode['optionlists'] = 'DLS';
PageIdentifierToEntityTypeCode['organization'] = 'ORG';
PageIdentifierToEntityTypeCode['organizations'] = 'ORG';
PageIdentifierToEntityTypeCode['people'] = 'PER';
PageIdentifierToEntityTypeCode['profile'] = 'PER';
PageIdentifierToEntityTypeCode['deployment'] = 'PHY';
PageIdentifierToEntityTypeCode['deployments'] = 'PHY';
// TODO - Remove this physical reference once everything is refactored for deployments
// To be used only when referencing deployments via openCreateModal()
PageIdentifierToEntityTypeCode['physical'] = 'PHY';
PageIdentifierToEntityTypeCode['product'] = 'PRD';
PageIdentifierToEntityTypeCode['products'] = 'PRD';
PageIdentifierToEntityTypeCode['scorecard'] = 'AUD';
PageIdentifierToEntityTypeCode['skill'] = 'SKI';
PageIdentifierToEntityTypeCode['skills'] = 'SKI';
PageIdentifierToEntityTypeCode['skills-inuse'] = 'SKI';
PageIdentifierToEntityTypeCode['standard'] = 'STA';
PageIdentifierToEntityTypeCode['standards'] = 'STA';
PageIdentifierToEntityTypeCode['standards-inuse'] = 'STA';
PageIdentifierToEntityTypeCode['strategy'] = 'STR';
PageIdentifierToEntityTypeCode['strategies'] = 'STR';
PageIdentifierToEntityTypeCode['system'] = 'SYS';
PageIdentifierToEntityTypeCode['systems'] = 'SYS';
PageIdentifierToEntityTypeCode['tag'] = 'TAG';
PageIdentifierToEntityTypeCode['tags'] = 'TAG';
PageIdentifierToEntityTypeCode['task'] = 'TSK';
PageIdentifierToEntityTypeCode['tasks'] = 'TSK';
PageIdentifierToEntityTypeCode['technology'] = 'TEC';
PageIdentifierToEntityTypeCode['technologies'] = 'TEC';
PageIdentifierToEntityTypeCode['technologies-inuse'] = 'TEC';
PageIdentifierToEntityTypeCode['tenantendpoint'] = 'TEP';
PageIdentifierToEntityTypeCode['tenantendpoints'] = 'TEP';
PageIdentifierToEntityTypeCode['workspace'] = 'CIR';
PageIdentifierToEntityTypeCode['workspaces'] = 'CIR';
PageIdentifierToEntityTypeCode['worksheet2'] = 'WK2';
PageIdentifierToEntityTypeCode['worksheets2'] = 'WK2';
PageIdentifierToEntityTypeCode['worksheet'] = 'WK2';
PageIdentifierToEntityTypeCode['worksheets'] = 'WK2';
PageIdentifierToEntityTypeCode['bubblechart'] = '044S00000001';
PageIdentifierToEntityTypeCode['bubblecharts'] = '044S00000001';
PageIdentifierToEntityTypeCode['fieldaggregation'] = '044S00000006';
PageIdentifierToEntityTypeCode['fieldaggregations'] = '044S00000006';
PageIdentifierToEntityTypeCode['lifecycleroadmap'] = '044S00000007';
PageIdentifierToEntityTypeCode['lifecycleroadmaps'] = '044S00000007';
PageIdentifierToEntityTypeCode['roadmap'] = '044S0000000K';
PageIdentifierToEntityTypeCode['kanban'] = '044S0000000J';
PageIdentifierToEntityTypeCode['priorityboard'] = '044S0000000L';
PageIdentifierToEntityTypeCode['matrix'] = '044S00000003';
PageIdentifierToEntityTypeCode['matrices'] = '044S00000003';
PageIdentifierToEntityTypeCode['radialconnection'] = '044S00000008';
PageIdentifierToEntityTypeCode['radialconnections'] = '044S00000008';
PageIdentifierToEntityTypeCode['relationaldiagram'] = '044S00000009';
PageIdentifierToEntityTypeCode['relationaldiagrams'] = '044S00000009';
PageIdentifierToEntityTypeCode['customdashboard'] = 'LYT';
PageIdentifierToEntityTypeCode['customdashboards'] = 'LYT';

var singleTypedCollectionPages = {};
singleTypedCollectionPages['ACT'] = 'actors';
singleTypedCollectionPages['ADQ'] = 'reports';
singleTypedCollectionPages['AST'] = 'documents';
singleTypedCollectionPages['AUD'] = 'scorecards';
singleTypedCollectionPages['CAP'] = 'capabilities';
singleTypedCollectionPages['CIR'] = 'workspaces';
singleTypedCollectionPages['COM'] = 'companies';
singleTypedCollectionPages['CON'] = 'connections';
singleTypedCollectionPages['CRL'] = 'rules';
singleTypedCollectionPages['DAT'] = 'data';
singleTypedCollectionPages['DEM'] = 'demands';
singleTypedCollectionPages['MKT'] = 'markets';
singleTypedCollectionPages['DLS'] = 'optionlists';
singleTypedCollectionPages['ORG'] = 'organizations';
singleTypedCollectionPages['PER'] = 'people';
singleTypedCollectionPages['PHY'] = 'deployments';
singleTypedCollectionPages['PRD'] = 'products';
singleTypedCollectionPages['RFM'] = 'remediationforms';
singleTypedCollectionPages['SKI'] = 'skills';
singleTypedCollectionPages['STA'] = 'standards';
singleTypedCollectionPages['STR'] = 'strategies';
singleTypedCollectionPages['SYS'] = 'systems';
singleTypedCollectionPages['TAG'] = 'tags';
singleTypedCollectionPages['TSK'] = 'tasks';
singleTypedCollectionPages['TEC'] = 'technologies';
singleTypedCollectionPages['TEP'] = 'tenantendpoints';
singleTypedCollectionPages['WKS'] = 'worksheets';
singleTypedCollectionPages['WK2'] = 'worksheets';
singleTypedCollectionPages['WK2_BUB'] = 'bubblecharts';
singleTypedCollectionPages['WK2_FLA'] = 'fieldaggregations';
singleTypedCollectionPages['WK2_LCR'] = 'lifecycleroadmaps';
singleTypedCollectionPages['WK2_MAT'] = 'matrices';
singleTypedCollectionPages['WK2_RAC'] = 'radialconnections';
singleTypedCollectionPages['WK2_RDM'] = 'relationaldiagrams';
singleTypedCollectionPages['WK2_SIS'] = 'systeminventorystatuses';
singleTypedCollectionPages['LYT'] = 'customdashboards';

var SingleEntityPageNames = {};
SingleEntityPageNames['ACT'] = 'actor';
SingleEntityPageNames['ADQ'] = 'report';
SingleEntityPageNames['AST'] = 'document';
SingleEntityPageNames['AUD'] = 'scorecard';
SingleEntityPageNames['CAP'] = 'capability';
SingleEntityPageNames['CIR'] = 'workspace';
SingleEntityPageNames['COM'] = 'company';
SingleEntityPageNames['CON'] = 'connection';
SingleEntityPageNames['CRL'] = 'rule';
SingleEntityPageNames['DAT'] = 'data';
SingleEntityPageNames['DEM'] = 'demand';
SingleEntityPageNames['MKT'] = 'market';
SingleEntityPageNames['DLS'] = 'optionlist';
SingleEntityPageNames['ORG'] = 'organization';
SingleEntityPageNames['PER'] = 'profile';
SingleEntityPageNames['PHY'] = 'physical';
SingleEntityPageNames['PRD'] = 'product';
SingleEntityPageNames['RFM'] = 'remediationform';
SingleEntityPageNames['SKI'] = 'skill';
SingleEntityPageNames['STA'] = 'standard';
SingleEntityPageNames['STR'] = 'strategy';
SingleEntityPageNames['SYS'] = 'system';
SingleEntityPageNames['TAG'] = 'tag';
SingleEntityPageNames['TSK'] = 'task';
SingleEntityPageNames['TEC'] = 'technology';
SingleEntityPageNames['TEP'] = 'tenantendpoint';
SingleEntityPageNames['WKS'] = 'worksheet';
SingleEntityPageNames['WK2'] = 'worksheet';
SingleEntityPageNames['WK2_BUB'] = 'bubblechart';
SingleEntityPageNames['WK2_FLA'] = 'fieldaggregation';
SingleEntityPageNames['WK2_LCR'] = 'lifecycleroadmap';
SingleEntityPageNames['WK2_MAT'] = 'matrix';
SingleEntityPageNames['WK2_RAC'] = 'radialconnection';
SingleEntityPageNames['WK2_RDM'] = 'relationaldiagram';
SingleEntityPageNames['WK2_SIS'] = 'systeminventorystatus';
SingleEntityPageNames['LYT'] = 'customdashboard';

var EntityTypes = {};
EntityTypes['ACT'] = {typeCode: 'ACT', bnCode: '01', displayName: '@@Actor', displayNamePlural: '@@Actors', pageName: 'actor'};
EntityTypes['ADB'] = {typeCode: 'ADB', bnCode: '4G', displayName: '@@Adapter_Batch', displayNamePlural: '@@Adapter_Batches'};
EntityTypes['ADP'] = {typeCode: 'ADP', bnCode: '4J', displayName: '@@Adapter_Property', displayNamePlural: '@@Adapter_Properties'};
EntityTypes['AUD'] = {typeCode: 'AUD', bnCode: '4A', displayName: '@@Scorecard', displayNamePlural: '@@Scorecards'};
EntityTypes['ADQ'] = {typeCode: 'ADQ', bnCode: '24', displayName: '@@Report', displayNamePlural: '@@Reports'};
EntityTypes['ALI'] = {typeCode: 'ALI', bnCode: '0U', displayName: '@@Alias', displayNamePlural: '@@Aliases'};
EntityTypes['ARL'] = {typeCode: 'ARL', bnCode: '03', displayName: '@@Authorize_Role', displayNamePlural: '@@Authorize_Roles'};
EntityTypes['ASM'] = {typeCode: 'ASM', bnCode: '1T', displayName: '@@Document_Meta-Data', displayNamePlural: '@@Document_Meta-Data_Plural'};
EntityTypes['ASP'] = {typeCode: 'ASP', bnCode: '1U', displayName: '@@Document_Part', displayNamePlural: '@@Document_Parts'};
EntityTypes['AST'] = {typeCode: 'AST', bnCode: '0U', displayName: '@@Document', displayNamePlural: '@@Documents'};
EntityTypes['ASV'] = {typeCode: 'ASV', bnCode: '2Z', displayName: '@@Document_Version', displayNamePlural: '@@Document_Versions'};
EntityTypes['AUR'] = {typeCode: 'AUR', bnCode: '4B', displayName: '@@Audit_Roll-up', displayNamePlural: '@@Audit_Roll-ups'};
EntityTypes['AUT'] = {typeCode: 'AUT', bnCode: '45', displayName: '@@User', displayNamePlural: '@@Users'};  // Authorize User
EntityTypes['CAP'] = {typeCode: 'CAP', bnCode: '0Q', displayName: '@@Capability', displayNamePlural: '@@Capabilities'};
EntityTypes['CDS'] = {typeCode: 'CDS', bnCode: '08', displayName: '@@Connection_Data_Structure', displayNamePlural: '@@Connection_Data_Structures'};
EntityTypes['CFO'] = {typeCode: 'CFO', bnCode: '09', displayName: '@@Connection_Format', displayNamePlural: '@@Connection_Formats'};
EntityTypes['CFQ'] = {typeCode: 'CFQ', bnCode: '0A', displayName: '@@Connection_Frequency', displayNamePlural: '@@Connection_Frequencies'};
EntityTypes['CIR'] = {typeCode: 'CIR', bnCode: '2O', displayName: '@@Workspace', displayNamePlural: '@@Workspaces'};
EntityTypes['COM'] = {typeCode: 'COM', bnCode: '2F', displayName: '@@Company', displayNamePlural: '@@Companies'};
EntityTypes['CON'] = {typeCode: 'CON', bnCode: '07', displayName: '@@Connection', displayNamePlural: '@@Connections'};
EntityTypes['CRL'] = {typeCode: 'CRL', bnCode: '3Z', displayName: '@@Rule', displayNamePlural: '@@Rules'};
EntityTypes['CFQ'] = {typeCode: 'CFQ', bnCode: '0A', displayName: '@@Connection_Frequency', displayNamePlural: '@@Connection_Frequencies'};
EntityTypes['CTN'] = {typeCode: 'CTN', bnCode: '0B', displayName: '@@Connection_Pattern', displayNamePlural: '@@Connection_Patterns'};
EntityTypes['CTP'] = {typeCode: 'CTP', bnCode: '0C', displayName: '@@Connection_Transport', displayNamePlural: '@@Connection_Transports'};
EntityTypes['CTT'] = {typeCode: 'CTT', bnCode: '0F', displayName: '@@Contact_Type', displayNamePlural: '@@Contact_Types'};
EntityTypes['CTY'] = {typeCode: 'CTY', bnCode: '0D', displayName: '@@Connection_Type', displayNamePlural: '@@Connection_Types'};
EntityTypes['DAT'] = {typeCode: 'DAT', bnCode: '44', displayName: '@@Data', displayNamePlural: '@@Data_Plural'};
EntityTypes['DEM'] = {typeCode: 'DEM', bnCode: '39', displayName: '@@Demand', displayNamePlural: '@@Demands'};
EntityTypes['DAY'] = {typeCode: 'DAY', bnCode: '0R', displayName: '@@News_Item', displayNamePlural: '@@News'}; //dashboard activity stream
EntityTypes['DLI'] = {typeCode: 'DLI', bnCode: '0M', displayName: '@@Option_List_Item', displayNamePlural: '@@Option_List_Items'};
EntityTypes['DLS'] = {typeCode: 'DLS', bnCode: '0K', displayName: '@@Option_List', displayNamePlural: '@@Option_Lists'};
EntityTypes['EAY'] = {typeCode: 'EAY', bnCode: '0S', displayName: '@@History', displayNamePlural: '@@History'}; //entity activity stream
EntityTypes['EMA'] = {typeCode: 'EMA', bnCode: '1Y', displayName: '@@Email', displayNamePlural: '@@Emails'};
EntityTypes['FLD'] = {typeCode: 'FLD', bnCode: '0L', displayName: '@@Field', displayNamePlural: '@@Fields'};
EntityTypes['FST'] = {typeCode: 'FST', bnCode: '0I', displayName: '@@Field_Set', displayNamePlural: '@@Field_Sets'};
EntityTypes['FVA'] = {typeCode: 'FVA', bnCode: '0P', displayName: '@@Field_Value', displayNamePlural: '@@Field_Values'};
EntityTypes['GEO'] = {typeCode: 'GEO', bnCode: '0T', displayName: '@@Geography', displayNamePlural: '@@Geographies'};
EntityTypes['LNK'] = {typeCode: 'LNK', bnCode: '0V', displayName: '@@Link', displayNamePlural: '@@Links'};
EntityTypes['LOC'] = {typeCode: 'LOC', bnCode: '1N', displayName: '@@Location', displayNamePlural: '@@Locations'};
EntityTypes['MKT'] = {typeCode: 'MKT', bnCode: '0T', displayName: '@@Market', displayNamePlural: '@@Markets'};
EntityTypes['ORG'] = {typeCode: 'ORG', bnCode: '0Y', displayName: '@@Organization', displayNamePlural: '@@Organizations', assocs: ['SYS', 'AST']};
EntityTypes['OUT'] = {typeCode: 'OUT', bnCode: '3C', displayName: '@@Outline_Item', displayNamePlural: '@@Outline_Items'};
EntityTypes['PAR'] = {typeCode: 'PAR', bnCode: '3O', displayName: '@@Participation', displayNamePlural: '@@Participations'};
EntityTypes['PER'] = {typeCode: 'PER', bnCode: '0E', displayName: '@@Person', displayNamePlural: '@@People'};
EntityTypes['PHN'] = {typeCode: 'PHN', bnCode: '1Z', displayName: '@@Phone_Number', displayNamePlural: '@@Phone_Numbers'};
EntityTypes['PHY'] = {typeCode: 'PHY', bnCode: '4L', displayName: '@@Deployment', displayNamePlural: '@@Deployments'};
EntityTypes['PLL'] = {typeCode: 'PLL', bnCode: '49', displayName: '@@Planned_Lifecycle', displayNamePlural: '@@Planned_Lifecycles'};
EntityTypes['POS'] = {typeCode: 'POS', bnCode: '26', displayName: '@@Position', displayNamePlural: '@@Positions'};
EntityTypes['PRD'] = {typeCode: 'PRD', bnCode: '0Z', displayName: '@@Product', displayNamePlural: '@@Products'};
EntityTypes['RFM'] = {typeCode: 'RFM', bnCode: '4P', displayName: '@@Remediation_Form', displayNamePlural: '@@Remediation_Forms'};
EntityTypes['SKE'] = {typeCode: 'SKE', bnCode: '2N', displayName: '@@Skill_Level', displayNamePlural: '@@Skill_Levels'};
EntityTypes['SKI'] = {typeCode: 'SKI', bnCode: '15', displayName: '@@Skill', displayNamePlural: '@@Skills'};
EntityTypes['STA'] = {typeCode: 'STA', bnCode: '16', displayName: '@@Standard', displayNamePlural: '@@Standards'};
EntityTypes['STR'] = {typeCode: 'STR', bnCode: '0G', displayName: '@@Strategy', displayNamePlural: '@@Strategies'};
EntityTypes['SYS'] = {typeCode: 'SYS', bnCode: '18', displayName: '@@System', displayNamePlural: '@@Systems'};
EntityTypes['TAG'] = {typeCode: 'TAG', bnCode: '1F', displayName: '@@Tag', displayNamePlural: '@@Tags'};
EntityTypes['TSK'] = {typeCode: 'TSK', bnCode: '4O', displayName: '@@Task', displayNamePlural: '@@Tasks'};
EntityTypes['TEC'] = {typeCode: 'TEC', bnCode: '1H', displayName: '@@Technology', displayNamePlural: '@@Technologies'};
EntityTypes['TEN'] = {typeCode: 'TEN', bnCode: '1K', displayName: '@@Tenant', displayNamePlural: '@@Tenants'};
EntityTypes['TEP'] = {typeCode: 'TEP', bnCode: '02', displayName: '@@Endpoint', displayNamePlural: '@@Endpoints'};
EntityTypes['TOP'] = {typeCode: 'TOP', bnCode: '2P', displayName: '@@Discussion', displayNamePlural: '@@Discussions'};
EntityTypes['WKS'] = {typeCode: 'WKS', bnCode: '06', displayName: '@@Worksheet', displayNamePlural: '@@Worksheets'};
EntityTypes['WK2'] = {typeCode: 'WK2', bnCode: '4M', displayName: '@@Worksheet', displayNamePlural: '@@Worksheets'};
EntityTypes['LYT'] = {typeCode: 'LYT', bnCode: '1L', displayName: '@@Dashboard', displayNamePlural: '@@Dashboards'};

var InsightTypes = {};
InsightTypes['044S00000001'] = {typeCode: 'WK2', bnCode: '4M', displayName: '@@Bubble_Chart', displayNamePlural: '@@Bubble_Charts'};
InsightTypes['044S00000002'] = {typeCode: 'CIR', bnCode: '2O', displayName: '@@Workspace', displayNamePlural: '@@Workspaces'};
InsightTypes['044S00000003'] = {typeCode: 'WK2', bnCode: '4M', displayName: '@@Matrix', displayNamePlural: '@@Matrices'};
InsightTypes['044S00000004'] = {typeCode: 'LYT', bnCode: '1L', displayName: '@@Dashboard', displayNamePlural: '@@Dashboards'};
InsightTypes['04430000000X'] = {typeCode: 'AST', bnCode: '0U', displayName: '@@Document', displayNamePlural: '@@Documents'};
InsightTypes['044S00000006'] = {typeCode: 'WK2', bnCode: '4M', displayName: '@@Field_Aggregation', displayNamePlural: '@@Field_Aggregations'};
InsightTypes['044S00000007'] = {typeCode: 'WK2', bnCode: '4M', displayName: '@@Lifecycle_Roadmap', displayNamePlural: '@@Lifecycle_Roadmaps'};
InsightTypes['044S00000008'] = {typeCode: 'WK2', bnCode: '4M', displayName: '@@Radial_Connection', displayNamePlural: '@@Radial_Connections'};
InsightTypes['044S00000009'] = {typeCode: 'WK2', bnCode: '4M', displayName: '@@Relational_Diagram', displayNamePlural: '@@Relational_Diagrams'};
InsightTypes['044S0000000B'] = {typeCode: 'ADQ', bnCode: '24', displayName: '@@Report', displayNamePlural: '@@Reports'};
InsightTypes['044S0000000C'] = {typeCode: 'CRL', bnCode: '3Z', displayName: '@@Rule', displayNamePlural: '@@Rules'};
InsightTypes['044S0000000D'] = {typeCode: 'AUD', bnCode: '4A', displayName: '@@Scorecard', displayNamePlural: '@@Scorecards'};
InsightTypes['04430000000J'] = {typeCode: 'TAG', bnCode: '1F', displayName: '@@Tag', displayNamePlural: '@@Tags'}; // bn is an data_dict bn
InsightTypes['044S0000000J'] = {typeCode: 'WK2', bnCode: '4M', displayName: '@@Kanban_Board', displayNamePlural: '@@Kanban_Boards'};
InsightTypes['044S0000000K'] = {typeCode: 'WK2', bnCode: '4M', displayName: '@@Roadmap', displayNamePlural: '@@Roadmap'};
InsightTypes['044S0000000L'] = {typeCode: 'WK2', bnCode: '4M', displayName: '@@Priority_Board', displayNamePlural: '@@Priority_Board'};

var EntityCodes = {};
EntityCodes['01'] = 'ACT';
EntityCodes['4G'] = 'ADB';
EntityCodes['4A'] = 'AUD';
EntityCodes['24'] = 'ADQ';
EntityCodes['0U'] = 'ALI';
EntityCodes['1T'] = 'ASM';
EntityCodes['1U'] = 'ASP';
EntityCodes['0U'] = 'AST';
EntityCodes['2Z'] = 'ASV';
EntityCodes['4A'] = 'AUD';
EntityCodes['4B'] = 'AUR';
EntityCodes['45'] = 'AUT';
EntityCodes['0Q'] = 'CAP';
EntityCodes['08'] = 'CDS';
EntityCodes['09'] = 'CFO';
EntityCodes['0A'] = 'CFQ';
EntityCodes['2O'] = 'CIR';
EntityCodes['2F'] = 'COM';
EntityCodes['07'] = 'CON';
EntityCodes['3Z'] = 'CRL';
EntityCodes['0A'] = 'CFQ';
EntityCodes['0B'] = 'CTN';
EntityCodes['0C'] = 'CTP';
EntityCodes['0F'] = 'CTT';
EntityCodes['0D'] = 'CTY';
EntityCodes['44'] = 'DAT';
EntityCodes['39'] = 'DEM';
EntityCodes['0R'] = 'DAY';
EntityCodes['0M'] = 'DLI';
EntityCodes['0K'] = 'DLS';
EntityCodes['0S'] = 'EAY';
EntityCodes['1Y'] = 'EMA';
EntityCodes['0L'] = 'FLD';
EntityCodes['0I'] = 'FST';
EntityCodes['0P'] = 'FVA';
EntityCodes['0T'] = 'GEO';
EntityCodes['0V'] = 'LNK';
EntityCodes['0T'] = 'MKT';
EntityCodes['0Y'] = 'ORG';
EntityCodes['3C'] = 'OUT';
EntityCodes['3O'] = 'PAR';
EntityCodes['0E'] = 'PER';
EntityCodes['1Z'] = 'PHN';
EntityCodes['4L'] = 'PHY';
EntityCodes['49'] = 'PLL';
EntityCodes['26'] = 'POS';
EntityCodes['0Z'] = 'PRD';
EntityCodes['4P'] = 'RFM';
EntityCodes['2N'] = 'SKE';
EntityCodes['15'] = 'SKI';
EntityCodes['16'] = 'STA';
EntityCodes['0G'] = 'STR';
EntityCodes['18'] = 'SYS';
EntityCodes['1F'] = 'TAG';
EntityCodes['4O'] = 'TSK';
EntityCodes['1H'] = 'TEC';
EntityCodes['1K'] = 'TEN';
EntityCodes['02'] = 'TEP';
EntityCodes['2P'] = 'TOP';
EntityCodes['06'] = 'WKS';
EntityCodes['4M'] = 'WK2';


var AggregationTypes = {};
AggregationTypes['SUM'] = {displayName: '@@Sum'};
AggregationTypes['MIN'] = {displayName: '@@Minimum'};
AggregationTypes['MAX'] = {displayName: '@@Maximum'};
AggregationTypes['AVG'] = {displayName: '@@Average'};


var fieldDataTypes = ['BOOLEAN', 'TEXT', 'DECIMAL', 'INTEGER', 'DATE', 'TIME', 'DATE_TIME'];

var fieldDataTypeToDisplayValue = {};
fieldDataTypeToDisplayValue['BOOLEAN'] = '@@Boolean';
fieldDataTypeToDisplayValue['TEXT'] = '@@Text';
fieldDataTypeToDisplayValue['DECIMAL'] = '@@Decimal';
fieldDataTypeToDisplayValue['INTEGER'] = '@@Integer';
fieldDataTypeToDisplayValue['DATE'] = '@@Date';
fieldDataTypeToDisplayValue['TIME'] = '@@Time';
fieldDataTypeToDisplayValue['DATE_TIME'] = '@@Date/Time';

var fieldDisplayTypeToDisplayValue = {};
fieldDisplayTypeToDisplayValue['CHECKBOX'] = '@@Check_Box';
fieldDisplayTypeToDisplayValue['CURRENCY'] = '@@Currency';
fieldDisplayTypeToDisplayValue['DROPDOWN'] = '@@Dropdown';
fieldDisplayTypeToDisplayValue['EMAIL'] = '@@Email';
fieldDisplayTypeToDisplayValue['MULTI_SELECT'] = '@@Multi_Select';
fieldDisplayTypeToDisplayValue['NUMBER'] = '@@Number';
fieldDisplayTypeToDisplayValue['RADIO_BUTTON'] = '@@Radio_Buttons';
fieldDisplayTypeToDisplayValue['SINGLE_SELECT'] = '@@Single_Select';
fieldDisplayTypeToDisplayValue['TEXT_AREA'] = '@@Text_Area';
fieldDisplayTypeToDisplayValue['TEXT_BOX'] = '@@Text_Box';
fieldDisplayTypeToDisplayValue['URL_LINK'] = '@@URL_Link';

var fieldDisplayTypeMatrix = {};
fieldDisplayTypeMatrix['BOOLEAN'] = ['DROPDOWN', 'RADIO_BUTTON'];
fieldDisplayTypeMatrix['TEXT'] = ['CHECKBOX', 'EMAIL', 'MULTI_SELECT', 'SINGLE_SELECT', 'TEXT_AREA', 'TEXT_BOX', 'URL_LINK'];
fieldDisplayTypeMatrix['DECIMAL'] = ['CHECKBOX', 'CURRENCY', 'MULTI_SELECT', 'NUMBER', 'SINGLE_SELECT'];
fieldDisplayTypeMatrix['INTEGER'] = ['CHECKBOX', 'MULTI_SELECT', 'NUMBER', 'SINGLE_SELECT'];
fieldDisplayTypeMatrix['DATE'] = ['CHECKBOX', 'MULTI_SELECT', 'SINGLE_SELECT', 'TEXT_BOX'];
fieldDisplayTypeMatrix['TIME'] = ['CHECKBOX', 'MULTI_SELECT', 'SINGLE_SELECT', 'TEXT_BOX'];
fieldDisplayTypeMatrix['DATE_TIME'] = ['CHECKBOX', 'MULTI_SELECT', 'SINGLE_SELECT', 'TEXT_BOX'];

var SectionTypeToIconAndTitle = {};
SectionTypeToIconAndTitle['Table'] = [EntityUtils.icons.table, '@@Table'];

SectionTypeToIconAndTitle['Activity'] = [EntityUtils.icons.activity, '@@History'];

SectionTypeToIconAndTitle['AdHocReport'] = [EntityUtils.icons.report, '@@Report_Overview'];
SectionTypeToIconAndTitle['AdHocReportBrowse'] = [EntityUtils.icons.report, '@@All_Reports'];
SectionTypeToIconAndTitle['AdHocReportRelatedAdHocReports'] = [EntityUtils.icons.architecture, '@@Reports'];
SectionTypeToIconAndTitle['AdHocReportRelatedComplianceRules'] = [EntityUtils.icons.compliance, '@@Rules'];
SectionTypeToIconAndTitle['AdHocReportOverview'] = [EntityUtils.icons.report, '@@Report_Overview'];
SectionTypeToIconAndTitle['AdHocReports'] = [EntityUtils.icons.report, '@@Reports'];

SectionTypeToIconAndTitle['AuditBrowse'] = [EntityUtils.icons.audit, '@@All_Audits'];
SectionTypeToIconAndTitle['AuditOverview'] = [EntityUtils.icons.audit, '@@Audit_Overview'];
SectionTypeToIconAndTitle['AuditTable'] = [EntityUtils.icons.table, '@@Audit_Results'];
SectionTypeToIconAndTitle['Audits'] = [EntityUtils.icons.audit, '@@Audits'];
SectionTypeToIconAndTitle['ScorecardBrowse'] = [EntityUtils.icons.audit, '@@All_Scorecards'];
SectionTypeToIconAndTitle['ScorecardOverview'] = [EntityUtils.icons.audit, '@@Scorecard_Overview'];
SectionTypeToIconAndTitle['ScorecardTable'] = [EntityUtils.icons.table, '@@Scorecard_Results'];
SectionTypeToIconAndTitle['Scorecards'] = [EntityUtils.icons.audit, '@@Scorecards'];

SectionTypeToIconAndTitle['ActorBrowse'] = [EntityUtils.icons.actor, '@@All_Actors'];
SectionTypeToIconAndTitle['ActorOverview'] = [EntityUtils.icons.actor, '@@Actor_Overview'];
SectionTypeToIconAndTitle['Actors'] = [EntityUtils.icons.actor, '@@Actors'];

SectionTypeToIconAndTitle['Capabilities'] = [EntityUtils.icons.capability, '@@Capabilities'];
SectionTypeToIconAndTitle['CapabilityArchitecture'] = [EntityUtils.icons.architecture, '@@Capability_Architecture'];
SectionTypeToIconAndTitle['CapabilityBrowse'] = [EntityUtils.icons.capability, '@@All_Capabilities'];
SectionTypeToIconAndTitle['CapabilityOverview'] = [EntityUtils.icons.capability, '@@Capability_Overview'];

SectionTypeToIconAndTitle['CircleBrowse'] = [EntityUtils.icons.circle, '@@All_Circles'];
SectionTypeToIconAndTitle['CircleCenteredOn'] = [EntityUtils.icons.circle, '@@Circle_Centered_On'];
SectionTypeToIconAndTitle['CircleOverview'] = [EntityUtils.icons.circle, '@@Circle_Overview'];
SectionTypeToIconAndTitle['CircleNews'] = [EntityUtils.icons.news, '@@Circle_News'];
SectionTypeToIconAndTitle['Circles'] = [EntityUtils.icons.circle, '@@Circles'];
SectionTypeToIconAndTitle['WorkspaceBrowse'] = [EntityUtils.icons.circle, '@@All_Workspaces'];
SectionTypeToIconAndTitle['WorkspaceCenteredOn'] = [EntityUtils.icons.search, '@@Manage_This_Workspace'];
SectionTypeToIconAndTitle['WorkspaceOverview'] = [EntityUtils.icons.circle, '@@Workspace_Overview'];
SectionTypeToIconAndTitle['WorkspaceNews'] = [EntityUtils.icons.news, '@@Workspace_News'];
SectionTypeToIconAndTitle['Workspaces'] = [EntityUtils.icons.circle, '@@Workspaces'];
SectionTypeToIconAndTitle['WorkspaceRelatedTiles'] = [EntityUtils.icons.search, '@@In_This_Workspace'];

SectionTypeToIconAndTitle['Companies'] = [EntityUtils.icons.company, '@@Companies'];
SectionTypeToIconAndTitle['CompanyBrowse'] = [EntityUtils.icons.company, '@@All_Companies'];
SectionTypeToIconAndTitle['CompanyOverview'] = [EntityUtils.icons.company, '@@Company_Overview'];

SectionTypeToIconAndTitle['ComplianceBrowse'] = [EntityUtils.icons.compliance, '@@All_Rules'];
SectionTypeToIconAndTitle['ComplianceOverview'] = [EntityUtils.icons.compliance, '@@Compliance_Overview'];
SectionTypeToIconAndTitle['ComplianceRuleAdHocReportsUsed'] = [EntityUtils.icons.report, '@@Reports_Used'];
SectionTypeToIconAndTitle['ComplianceRuleDependentAudits'] = [EntityUtils.icons.audit, '@@Dependent_Scorecards'];
SectionTypeToIconAndTitle['ComplianceRules'] = [EntityUtils.icons.compliance, '@@Rules'];
SectionTypeToIconAndTitle['ComplianceEntityCompliance'] = [EntityUtils.icons.compliance, '@@Entity_Compliance'];

SectionTypeToIconAndTitle['ConnectionBrowse'] = [EntityUtils.icons.connection, '@@All_Connections'];
SectionTypeToIconAndTitle['ConnectionOverview'] = [EntityUtils.icons.connection, '@@Connection_Overview'];
SectionTypeToIconAndTitle['Connections'] = [EntityUtils.icons.connection, '@@Connections'];
SectionTypeToIconAndTitle['SubConnections'] = [EntityUtils.icons.connectionSub, '@@Sub_Connections'];

SectionTypeToIconAndTitle['Data'] = [EntityUtils.icons.data, '@@Data'];
SectionTypeToIconAndTitle['DataArchitecture'] = [EntityUtils.icons.architecture, '@@Data_Architecture'];
SectionTypeToIconAndTitle['DataBrowse'] = [EntityUtils.icons.data, '@@All_Data'];
SectionTypeToIconAndTitle['DataOverview'] = [EntityUtils.icons.data, '@@Data_Overview'];

SectionTypeToIconAndTitle['Demands'] = [EntityUtils.icons.demand, '@@Demands'];
SectionTypeToIconAndTitle['DemandArchitecture'] = [EntityUtils.icons.architecture, '@@Demand_Model'];
SectionTypeToIconAndTitle['DemandNews'] = [EntityUtils.icons.news, '@@Demand_News'];
SectionTypeToIconAndTitle['DemandBrowse'] = [EntityUtils.icons.demand, '@@All_Demands'];
SectionTypeToIconAndTitle['DemandOverview'] = [EntityUtils.icons.demand, '@@Demand_Overview'];

SectionTypeToIconAndTitle['Discussions'] = [EntityUtils.icons.discussion, '@@Discussions'];

SectionTypeToIconAndTitle['DocumentBrowse'] = [EntityUtils.icons.document, '@@All_Documents'];
SectionTypeToIconAndTitle['DocumentOverview'] = [EntityUtils.icons.document, '@@Document_Overview'];
SectionTypeToIconAndTitle['Documents'] = [EntityUtils.icons.document, '@@Documents'];
SectionTypeToIconAndTitle['DocumentVersions'] = [EntityUtils.icons.document, '@@Versions'];

SectionTypeToIconAndTitle['Experience'] = [EntityUtils.icons.profile, '@@Experience'];

SectionTypeToIconAndTitle['Geographies'] = [EntityUtils.icons.geography, '@@Geographies'];

SectionTypeToIconAndTitle['Notes'] = [EntityUtils.icons.notes, '@@Notes'];

SectionTypeToIconAndTitle['OptionListBrowse'] = [EntityUtils.icons.gear, '@@All_Option_Lists'];

SectionTypeToIconAndTitle['OrganizationBrowse'] = [EntityUtils.icons.organization, '@@All_Organizations'];
SectionTypeToIconAndTitle['OrganizationOverview'] = [EntityUtils.icons.organization, '@@Organization_Overview'];
SectionTypeToIconAndTitle['OrganizationArchitecture'] = [EntityUtils.icons.architecture, '@@Organization_Chart'];
SectionTypeToIconAndTitle['Organizations'] = [EntityUtils.icons.organization, '@@Organizations'];

SectionTypeToIconAndTitle['Markets'] = [EntityUtils.icons.market, '@@Markets'];
SectionTypeToIconAndTitle['MarketArchitecture'] = [EntityUtils.icons.architecture, '@@Market_Model'];
SectionTypeToIconAndTitle['MarketNews'] = [EntityUtils.icons.news, '@@Market_News'];
SectionTypeToIconAndTitle['MarketBrowse'] = [EntityUtils.icons.market, '@@All_Markets'];
SectionTypeToIconAndTitle['MarketOverview'] = [EntityUtils.icons.market, '@@Market_Overview'];

SectionTypeToIconAndTitle['People'] = [EntityUtils.icons.people, '@@People'];
SectionTypeToIconAndTitle['PersonBrowse'] = [EntityUtils.icons.people, '@@All_People'];
SectionTypeToIconAndTitle['PersonOverview'] = [EntityUtils.icons.people, '@@Person_Overview'];
SectionTypeToIconAndTitle['PersonNews'] = [EntityUtils.icons.news, '@@News'];

SectionTypeToIconAndTitle['PhysicalArchitecture'] = [EntityUtils.icons.architecture, '@@Deployment_Architecture'];
SectionTypeToIconAndTitle['PhysicalBrowse'] = [EntityUtils.icons.physical, '@@All_Deployments'];
SectionTypeToIconAndTitle['PhysicalOverview'] = [EntityUtils.icons.physical, '@@Deployment_Overview'];
SectionTypeToIconAndTitle['Physicals'] = [EntityUtils.icons.physical, '@@Deployments'];
// As we migrate terms ...
SectionTypeToIconAndTitle['DeploymentArchitecture'] = [EntityUtils.icons.architecture, '@@Deployment_Architecture'];
SectionTypeToIconAndTitle['DeploymentBrowse'] = [EntityUtils.icons.physical, '@@All_Deployments'];
SectionTypeToIconAndTitle['DeploymentOverview'] = [EntityUtils.icons.physical, '@@Deployment_Overview'];
SectionTypeToIconAndTitle['Deployments'] = [EntityUtils.icons.physical, '@@Deployments'];

SectionTypeToIconAndTitle['ProductArchitecture'] = [EntityUtils.icons.architecture, '@@Product_Architecture'];
SectionTypeToIconAndTitle['ProductBrowse'] = [EntityUtils.icons.product, '@@All_Products'];
SectionTypeToIconAndTitle['ProductOverview'] = [EntityUtils.icons.product, '@@Product_Overview'];
SectionTypeToIconAndTitle['Products'] = [EntityUtils.icons.product, '@@Products'];

SectionTypeToIconAndTitle['SkillBrowse'] = [EntityUtils.icons.skill, '@@All_Skills'];
SectionTypeToIconAndTitle['SkillOverview'] = [EntityUtils.icons.skill, '@@Skill_Overview'];
SectionTypeToIconAndTitle['Skills'] = [EntityUtils.icons.skill, '@@Skills'];

SectionTypeToIconAndTitle['StandardBrowse'] = [EntityUtils.icons.standard, '@@All_Standards'];
SectionTypeToIconAndTitle['StandardOverview'] = [EntityUtils.icons.standard, '@@Standard_Overview'];
SectionTypeToIconAndTitle['Standards'] = [EntityUtils.icons.standard, '@@Standards'];

SectionTypeToIconAndTitle['Strategies'] = [EntityUtils.icons.strategy, '@@Strategies'];
SectionTypeToIconAndTitle['StrategyArchitecture'] = [EntityUtils.icons.architecture, '@@Strategy_Model'];
SectionTypeToIconAndTitle['StrategyNews'] = [EntityUtils.icons.news, '@@Strategy_News'];
SectionTypeToIconAndTitle['StrategyBrowse'] = [EntityUtils.icons.strategy, '@@All_Strategies'];
SectionTypeToIconAndTitle['StrategyOverview'] = [EntityUtils.icons.strategy, '@@Strategy_Overview'];

SectionTypeToIconAndTitle['SystemBrowse'] = [EntityUtils.icons.system, '@@All_Systems'];
SectionTypeToIconAndTitle['SystemOverview'] = [EntityUtils.icons.system, '@@System_Overview'];
SectionTypeToIconAndTitle['Systems'] = [EntityUtils.icons.system, '@@Systems'];
SectionTypeToIconAndTitle['SystemArchitecture'] = [EntityUtils.icons.architecture, '@@System_Architecture'];

SectionTypeToIconAndTitle['TagBrowse'] = [EntityUtils.icons.tag, '@@All_Tags'];
SectionTypeToIconAndTitle['TagOverview'] = [EntityUtils.icons.tag, '@@Tag_Overview'];
SectionTypeToIconAndTitle['Tags'] = [EntityUtils.icons.tag, '@@Tags'];

SectionTypeToIconAndTitle['Technologies'] = [EntityUtils.icons.technology, '@@Technologies'];
SectionTypeToIconAndTitle['TechnologyBrowse'] = [EntityUtils.icons.technology, '@@All_Technologies'];
SectionTypeToIconAndTitle['TechnologyOverview'] = [EntityUtils.icons.technology, '@@Technology_Overview'];

SectionTypeToIconAndTitle['TenantEndpointBrowse'] = [EntityUtils.icons.gear, '@@All_Endpoints'];

SectionTypeToIconAndTitle['WorksheetBrowse'] = [EntityUtils.icons.worksheet, '@@All_Worksheets'];
SectionTypeToIconAndTitle['Worksheet2Browse'] = [EntityUtils.icons.worksheet, '@@All_Worksheets'];
SectionTypeToIconAndTitle['BubbleChartBrowse'] = [EntityUtils.icons.worksheet, '@@All_Bubble_Charts'];
SectionTypeToIconAndTitle['FieldAggregationBrowse'] = [EntityUtils.icons.worksheet, '@@All_Field_Aggregations'];
SectionTypeToIconAndTitle['LifecycleRoadmapBrowse'] = [EntityUtils.icons.worksheet, '@@All_Lifecycle_Roadmaps'];
SectionTypeToIconAndTitle['MatrixBrowse'] = [EntityUtils.icons.worksheet, '@@All_Matrices'];
SectionTypeToIconAndTitle['OrganizationCoverageReportBrowse'] = [EntityUtils.icons.worksheet, '@@All_Organization_Coverage_Report'];
SectionTypeToIconAndTitle['RadialConnectionBrowse'] = [EntityUtils.icons.worksheet, '@@All_Relational_Diagram'];
SectionTypeToIconAndTitle['RelationalDiagramBrowse'] = [EntityUtils.icons.worksheet, '@@All_Relational_Diagram'];
SectionTypeToIconAndTitle['CustomDashboardBrowse'] = [EntityUtils.icons.dashboard, '@@All_Dashboards'];

SectionTypeToIconAndTitle['Worksheet2CenteredOn'] = [EntityUtils.icons.worksheet, '@@Worksheet_Centered_On'];
SectionTypeToIconAndTitle['Worksheet2Overview'] = [EntityUtils.icons.worksheet, '@@Worksheet_Overview'];
SectionTypeToIconAndTitle['WorksheetNews'] = [EntityUtils.icons.news, '@@Worksheet_News'];
SectionTypeToIconAndTitle['Worksheets'] = [EntityUtils.icons.worksheets, '@@Worksheets'];
SectionTypeToIconAndTitle['Worksheet2RelatedTiles'] = [EntityUtils.icons.search, '@@In_This_Worksheet'];

// Translated value for Non-english tenants on worksheet pages
// Work Differently than other entities
// MIGHT be able to remove the associated, non-translated versions as well.
SectionTypeToIconAndTitle['@@Actors'] = [EntityUtils.icons.actor, '@@Actors'];
SectionTypeToIconAndTitle['@@Capabilities'] = [EntityUtils.icons.capability, '@@Capabilities'];
SectionTypeToIconAndTitle['@@Companies'] = [EntityUtils.icons.company, '@@Companies'];
SectionTypeToIconAndTitle['@@Connections'] = [EntityUtils.icons.connection, '@@Connections'];
SectionTypeToIconAndTitle['@@Data'] = [EntityUtils.icons.data, '@@Data'];
SectionTypeToIconAndTitle['@@Demands'] = [EntityUtils.icons.demand, '@@Demands'];
SectionTypeToIconAndTitle['@@Deployments'] = [EntityUtils.icons.physical, '@@Deployments'];
SectionTypeToIconAndTitle['@@Markets'] = [EntityUtils.icons.market, '@@Markets'];
SectionTypeToIconAndTitle['@@Organizations'] = [EntityUtils.icons.organization, '@@Organizations'];
SectionTypeToIconAndTitle['@@Products'] = [EntityUtils.icons.product, '@@Products'];
SectionTypeToIconAndTitle['@@Skills'] = [EntityUtils.icons.skill, '@@Skills'];
SectionTypeToIconAndTitle['@@Standards'] = [EntityUtils.icons.standard, '@@Standards'];
SectionTypeToIconAndTitle['@@Strategies'] = [EntityUtils.icons.strategy, '@@Strategies'];
SectionTypeToIconAndTitle['@@Systems'] = [EntityUtils.icons.system, '@@Systems'];
SectionTypeToIconAndTitle['@@Technologies'] = [EntityUtils.icons.technology, '@@Technologies'];

var ReportNameToResourceUrl = {};
ReportNameToResourceUrl['orginventory'] = 'organization/systeminventoryreportresponse';
ReportNameToResourceUrl['orgskillcoveragereport'] = 'organization/orgskillcoveragereportresponse';
ReportNameToResourceUrl['orgstandardcoveragereport'] = 'organization/orgstandardcoveragereportresponse';
ReportNameToResourceUrl['orgtechcoveragereport'] = 'organization/orgtechcoveragereportresponse';
ReportNameToResourceUrl['orgcapabilitycoveragereport'] = 'organization/orgcapabilitycoveragereportresponse';
ReportNameToResourceUrl['orgcoveragereport'] = 'orgcoveragereportresponse';

// the available option-list types by entity type
var EntityOptionListTypes = {};
EntityOptionListTypes['ACT'] = [
    {dataListType: 'ACTOR_LIFECYCLE'}
];
EntityOptionListTypes['AST'] = [
    {dataListType: 'ASSET_LIFECYCLE'}
];
EntityOptionListTypes['CAP'] = [
    {dataListType: 'CAPABILITY_LIFECYCLE'},
    {dataListType: 'CAPABILITY_DOMAIN'},
    {dataListType: 'CAPABILITY_TYPE'}
];
EntityOptionListTypes['CIR'] = [
    {dataListType: 'CIRCLE_TYPE'}
    //{dataListType: 'CIRCLE_SUB_TYPE'}
];
EntityOptionListTypes['COM'] = [
    {dataListType: 'COMPANY_LIFECYCLE'},
    {dataListType: 'COMPANY_APPROVAL'}
];
EntityOptionListTypes['CON'] = [
    {dataListType: 'CONNECTION_LIFECYCLE'},
    {dataListType: 'CONNECTION_DATA_STRUCTURE'},
    {dataListType: 'CONNECTION_FORMAT'},
    {dataListType: 'CONNECTION_FREQUENCY'},
    {dataListType: 'CONNECTION_TRANSPORT'}
];
EntityOptionListTypes['DAT'] = [
    {dataListType: 'DATA_APPROVAL'},
    {dataListType: 'DATA_DOMAIN'},
    {dataListType: 'DATA_LIFECYCLE'},
    {dataListType: 'DATA_TYPE'}
];
EntityOptionListTypes['DEM'] = [
    {dataListType: 'DEMAND_TYPE'},
    {dataListType: 'DEMAND_APPROVAL'},
    {dataListType: 'DEMAND_DOMAIN'},
    {dataListType: 'DEMAND_LIFECYCLE'},
    {dataListType: 'DEMAND_PRIORITY'},
    {dataListType: 'DEMAND_SCHEDULE_STATUS'},
    {dataListType: 'DEMAND_BUDGET_STATUS'},
    {dataListType: 'DEMAND_BUSINESS_VALUE'},
    {dataListType: 'DEMAND_INVESTMENT_RANGE'},
    {dataListType: 'DEMAND_INVESTMENT_SCALE'},
    {dataListType: 'DEMAND_RISK_LEVEL'},
    {dataListType: 'DEMAND_FUNDING_TYPE'}
];
EntityOptionListTypes['MKT'] = [
    {dataListType: 'MARKET_DOMAIN'},
    {dataListType: 'MARKET_LIFECYCLE'},
    {dataListType: 'MARKET_TYPE'}
];
EntityOptionListTypes['ORG'] = [
    {dataListType: 'ORGANIZATION_DOMAIN'}
];
EntityOptionListTypes['PHY'] = [
    {dataListType: 'PHYSICAL_DOMAIN'},
    {dataListType: 'PHYSICAL_LIFECYCLE'},
    {dataListType: 'PHYSICAL_TYPE'}
];
EntityOptionListTypes['PRD'] = [
    {dataListType: 'PRODUCT_DOMAIN'},
    {dataListType: 'PRODUCT_LIFECYCLE'},
    {dataListType: 'PRODUCT_TYPE'}
];
EntityOptionListTypes['SKI'] = [
    {dataListType: 'SKILL_LIFECYCLE'},
    {dataListType: 'SKILL_APPROVAL'}
];
EntityOptionListTypes['STA'] = [
    {dataListType: 'STANDARD_LIFECYCLE'},
    {dataListType: 'STANDARD_APPROVAL'}
];
EntityOptionListTypes['STR'] = [
    {dataListType: 'STRATEGY_TYPE'},
    {dataListType: 'STRATEGY_APPROVAL'},
    {dataListType: 'STRATEGY_DOMAIN'},
    {dataListType: 'STRATEGY_LIFECYCLE'},
    {dataListType: 'STRATEGY_PRIORITY'},
    {dataListType: 'STRATEGY_URGENCY'},
    {dataListType: 'STRATEGY_BUSINESS_VALUE'},
    {dataListType: 'STRATEGY_RISK_LEVEL'},
    {dataListType: 'STRATEGY_INVESTMENT_SCALE'},
    {dataListType: 'STRATEGY_VALUE_HORIZON'},
    {dataListType: 'STRATEGY_VALUE_PROFILE'},
    {dataListType: 'STRATEGY_IMPACT_PROFILE'}
];
EntityOptionListTypes['SYS'] = [
    {dataListType: 'SYSTEM_DOMAIN'},
    {dataListType: 'SYSTEM_PRIORITY'},
    {dataListType: 'SYSTEM_TYPE'},
    {dataListType: 'SYSTEM_LIFECYCLE'}
];
EntityOptionListTypes['TEC'] = [
    {dataListType: 'TECHNOLOGY_LIFECYCLE'},
    {dataListType: 'TECHNOLOGY_DOMAIN'},
    {dataListType: 'TECHNOLOGY_APPROVAL'}
];
EntityOptionListTypes['TSK'] = [
    {dataListType: 'TASK_STATUS'}
];
EntityOptionListTypes['WK2'] = [
  {dataListType: 'CIRCLE_SUB_TYPE'}
];

// Used for getting Qualifiers for Assocs (Assoc CSV Help)
var EntityQualifierTypes = {};
EntityQualifierTypes['ACT'] = [
  {dataListType: 'STRATEGY_ACTOR_QUALIFIER'},
  {dataListType: 'DEMAND_ACTOR_QUALIFIER'},
  {dataListType: 'ACTOR_ORGANIZATION_QUALIFIER'},
  {dataListType: 'PRODUCT_ACTOR_QUALIFIER'},
  {dataListType: 'ACTOR_MARKET_QUALIFIER'},
  {dataListType: 'PHYSICAL_ACTOR_QUALIFIER'}
];
EntityQualifierTypes['CAP'] = [
  {dataListType: 'STRATEGY_CAPABILITY_QUALIFIER'},
  {dataListType: 'DEMAND_CAPABILITY_QUALIFIER'},
  {dataListType: 'CAPABILITY_ORGANIZATION_QUALIFIER'},
  {dataListType: 'CAPABILITY_CONTACT_TYPE'},
  {dataListType: 'PRODUCT_CAPABILITY_QUALIFIER'},
  {dataListType: 'CAPABILITY_MARKET_QUALIFIER'},
  {dataListType: 'SYSTEM_CAPABILITY_QUALIFIER'},
  {dataListType: 'CAPABILITY_TECHNOLOGY_QUALIFIER'},
  {dataListType: 'CAPABILITY_STANDARD_QUALIFIER'},
  {dataListType: 'CAPABILITY_SKILL_QUALIFIER'},
  {dataListType: 'PHYSICAL_CAPABILITY_QUALIFIER'},
  {dataListType: 'CAPABILITY_RELATIONSHIP_QUALIFIER'}
];
EntityQualifierTypes['CON'] = [
  {dataListType: 'STRATEGY_CONNECTION_QUALIFIER'},
  {dataListType: 'DEMAND_CONNECTION_QUALIFIER'},
  {dataListType: 'CONNECTION_ORGANIZATION_QUALIFIER'},
  {dataListType: 'CONNECTION_CONTACT_TYPE'},
  {dataListType: 'PRODUCT_CONNECTION_QUALIFIER'},
  {dataListType: 'CONNECTION_MARKET_QUALIFIER'},
  {dataListType: 'PHYSICAL_CONNECTION_QUALIFIER'}
];
EntityQualifierTypes['DAT'] = [
  {dataListType: 'STRATEGY_DATA_QUALIFIER'},
  {dataListType: 'DEMAND_DATA_QUALIFIER'},
  {dataListType: 'DATA_ORGANIZATION_QUALIFIER'},
  {dataListType: 'DATA_CONTACT_TYPE'},
  {dataListType: 'PRODUCT_DATA_QUALIFIER'},
  {dataListType: 'DATA_MARKET_QUALIFIER'},
  {dataListType: 'DATA_SYSTEM_QUALIFIER'},
  {dataListType: 'PHYSICAL_DATA_QUALIFIER'},
  {dataListType: 'DATA_RELATIONSHIP_QUALIFIER'}
];
EntityQualifierTypes['MKT'] = [
  {dataListType: 'STRATEGY_MARKET_QUALIFIER'},
  {dataListType: 'DEMAND_MARKET_QUALIFIER'},
  {dataListType: 'MARKET_ORGANIZATION_QUALIFIER'},
  {dataListType: 'MARKET_CONTACT_TYPE'},
  {dataListType: 'ACTOR_MARKET_QUALIFIER'},
  {dataListType: 'CAPABILITY_MARKET_QUALIFIER'},
  {dataListType: 'PRODUCT_MARKET_QUALIFIER'},
  {dataListType: 'DATA_MARKET_QUALIFIER'},
  {dataListType: 'SYSTEM_MARKET_QUALIFIER'},
  {dataListType: 'CONNECTION_MARKET_QUALIFIER'},
  {dataListType: 'PHYSICAL_MARKET_QUALIFIER'},
  {dataListType: 'MARKET_RELATIONSHIP_QUALIFIER'}
];
EntityQualifierTypes['PRD'] = [
  {dataListType: 'STRATEGY_PRODUCT_QUALIFIER'},
  {dataListType: 'DEMAND_PRODUCT_QUALIFIER'},
  {dataListType: 'PRODUCT_ORGANIZATION_QUALIFIER'},
  {dataListType: 'PRODUCT_CONTACT_TYPE'},
  {dataListType: 'PRODUCT_ACTOR_QUALIFIER'},
  {dataListType: 'PRODUCT_CAPABILITY_QUALIFIER'},
  {dataListType: 'PRODUCT_MARKET_QUALIFIER'},
  {dataListType: 'PRODUCT_DATA_QUALIFIER'},
  {dataListType: 'PRODUCT_SYSTEM_QUALIFIER'},
  {dataListType: 'PRODUCT_CONNECTION_QUALIFIER'},
  {dataListType: 'PRODUCT_TECHNOLOGY_QUALIFIER'},
  {dataListType: 'PRODUCT_STANDARD_QUALIFIER'},
  {dataListType: 'PRODUCT_SKILL_QUALIFIER'},
  {dataListType: 'PHYSICAL_PRODUCT_QUALIFIER'},
  {dataListType: 'PRODUCT_RELATIONSHIP_QUALIFIER'}
];
EntityQualifierTypes['SKI'] = [
  {dataListType: 'SKILL_CONTACT_TYPE'},
  {dataListType: 'STRATEGY_SKILL_QUALIFIER'},
  {dataListType: 'DEMAND_SKILL_QUALIFIER'},
  {dataListType: 'CAPABILITY_SKILL_QUALIFIER'},
  {dataListType: 'PRODUCT_SKILL_QUALIFIER'},
  {dataListType: 'PHYSICAL_SKILL_QUALIFIER'}
];
EntityQualifierTypes['STA'] = [
  {dataListType: 'STRATEGY_STANDARD_QUALIFIER'},
  {dataListType: 'DEMAND_STANDARD_QUALIFIER'},
  {dataListType: 'STANDARD_CONTACT_TYPE'},
  {dataListType: 'CAPABILITY_STANDARD_QUALIFIER'},
  {dataListType: 'PRODUCT_STANDARD_QUALIFIER'},
  {dataListType: 'PHYSICAL_STANDARD_QUALIFIER'}
];
EntityQualifierTypes['SYS'] = [
  {dataListType: 'DEMAND_SYSTEM_QUALIFIER'},
  {dataListType: 'STRATEGY_SYSTEM_QUALIFIER'},
  {dataListType: 'SYSTEM_ORGANIZATION_QUALIFIER'},
  {dataListType: 'SYSTEM_CONTACT_TYPE'},
  {dataListType: 'SYSTEM_CAPABILITY_QUALIFIER'},
  {dataListType: 'PRODUCT_SYSTEM_QUALIFIER'},
  {dataListType: 'SYSTEM_TECHNOLOGY_QUALIFIER'},
  {dataListType: 'DATA_SYSTEM_QUALIFIER'},
  {dataListType: 'SYSTEM_MARKET_QUALIFIER'},
  {dataListType: 'PHYSICAL_SYSTEM_QUALIFIER'}
];
EntityQualifierTypes['TEC'] = [
  {dataListType: 'STRATEGY_TECHNOLOGY_QUALIFIER'},
  {dataListType: 'DEMAND_TECHNOLOGY_QUALIFIER'},
  {dataListType: 'TECHNOLOGY_ORGANIZATION_QUALIFIER'},
  {dataListType: 'TECHNOLOGY_CONTACT_TYPE'},
  {dataListType: 'CAPABILITY_TECHNOLOGY_QUALIFIER'},
  {dataListType: 'PRODUCT_TECHNOLOGY_QUALIFIER'},
  {dataListType: 'SYSTEM_TECHNOLOGY_QUALIFIER'},
  {dataListType: 'PHYSICAL_TECHNOLOGY_QUALIFIER'}
];
EntityQualifierTypes['ORG'] = [
  {dataListType: 'STRATEGY_ORGANIZATION_QUALIFIER'},
  {dataListType: 'DEMAND_ORGANIZATION_QUALIFIER'},
  {dataListType: 'ACTOR_ORGANIZATION_QUALIFIER'},
  {dataListType: 'CAPABILITY_ORGANIZATION_QUALIFIER'},
  {dataListType: 'PRODUCT_ORGANIZATION_QUALIFIER'},
  {dataListType: 'MARKET_ORGANIZATION_QUALIFIER'},
  {dataListType: 'DATA_ORGANIZATION_QUALIFIER'},
  {dataListType: 'SYSTEM_ORGANIZATION_QUALIFIER'},
  {dataListType: 'CONNECTION_ORGANIZATION_QUALIFIER'},
  {dataListType: 'TECHNOLOGY_ORGANIZATION_QUALIFIER'},
  {dataListType: 'PHYSICAL_ORGANIZATION_QUALIFIER'}
];
EntityQualifierTypes['COM'] = [
  {dataListType: 'STRATEGY_ORGANIZATION_QUALIFIER'},
  {dataListType: 'DEMAND_ORGANIZATION_QUALIFIER'},
  {dataListType: 'COMPANY_CONTACT_TYPE'},
  {dataListType: 'CAPABILITY_ORGANIZATION_QUALIFIER'},
  {dataListType: 'PRODUCT_ORGANIZATION_QUALIFIER'},
  {dataListType: 'MARKET_ORGANIZATION_QUALIFIER'},
  {dataListType: 'DATA_ORGANIZATION_QUALIFIER'},
  {dataListType: 'SYSTEM_ORGANIZATION_QUALIFIER'},
  {dataListType: 'PHYSICAL_ORGANIZATION_QUALIFIER'}
];
EntityQualifierTypes['PER'] = [
  {dataListType: 'STRATEGY_CONTACT_TYPE'},
  {dataListType: 'DEMAND_CONTACT_TYPE'},
  {dataListType: 'COMPANY_CONTACT_TYPE'},
  {dataListType: 'CAPABILITY_CONTACT_TYPE'},
  {dataListType: 'PRODUCT_CONTACT_TYPE'},
  {dataListType: 'MARKET_CONTACT_TYPE'},
  {dataListType: 'DATA_CONTACT_TYPE'},
  {dataListType: 'SYSTEM_CONTACT_TYPE'},
  {dataListType: 'CONNECTION_CONTACT_TYPE'},
  {dataListType: 'TECHNOLOGY_CONTACT_TYPE'},
  {dataListType: 'STANDARD_CONTACT_TYPE'},
  {dataListType: 'PHYSICAL_CONTACT_TYPE'}
];
EntityQualifierTypes['PHY'] = [
  {dataListType: 'STRATEGY_PHYSICAL_QUALIFIER'},
  {dataListType: 'DEMAND_PHYSICAL_QUALIFIER'},
  {dataListType: 'PHYSICAL_ORGANIZATION_QUALIFIER'},
  {dataListType: 'PHYSICAL_CONTACT_TYPE'},
  {dataListType: 'PHYSICAL_ORGANIZATION_QUALIFIER'},
  {dataListType: 'PHYSICAL_ACTOR_QUALIFIER'},
  {dataListType: 'PHYSICAL_CAPABILITY_QUALIFIER'},
  {dataListType: 'PHYSICAL_PRODUCT_QUALIFIER'},
  {dataListType: 'PHYSICAL_MARKET_QUALIFIER'},
  {dataListType: 'PHYSICAL_DATA_QUALIFIER'},
  {dataListType: 'PHYSICAL_SYSTEM_QUALIFIER'},
  {dataListType: 'PHYSICAL_CONNECTION_QUALIFIER'},
  {dataListType: 'PHYSICAL_TECHNOLOGY_QUALIFIER'},
  {dataListType: 'PHYSICAL_STANDARD_QUALIFIER'},
  {dataListType: 'PHYSICAL_SKILL_QUALIFIER'},
  {dataListType: 'PHYSICAL_RELATIONSHIP_QUALIFIER'}
];
EntityQualifierTypes['STR'] = [
  {dataListType: 'STRATEGY_DEMAND_QUALIFIER'},
  {dataListType: 'STRATEGY_ORGANIZATION_QUALIFIER'},
  {dataListType: 'STRATEGY_CONTACT_TYPE'},
  {dataListType: 'STRATEGY_ACTOR_QUALIFIER'},
  {dataListType: 'STRATEGY_CAPABILITY_QUALIFIER'},
  {dataListType: 'STRATEGY_PRODUCT_QUALIFIER'},
  {dataListType: 'STRATEGY_MARKET_QUALIFIER'},
  {dataListType: 'STRATEGY_DATA_QUALIFIER'},
  {dataListType: 'STRATEGY_SYSTEM_QUALIFIER'},
  {dataListType: 'STRATEGY_CONNECTION_QUALIFIER'},
  {dataListType: 'STRATEGY_TECHNOLOGY_QUALIFIER'},
  {dataListType: 'STRATEGY_STANDARD_QUALIFIER'},
  {dataListType: 'STRATEGY_SKILL_QUALIFIER'},
  {dataListType: 'STRATEGY_PHYSICAL_QUALIFIER'},
  {dataListType: 'STRATEGY_RELATIONSHIP_QUALIFIER'}
];
EntityQualifierTypes['DEM'] = [
  {dataListType: 'STRATEGY_DEMAND_QUALIFIER'},
  {dataListType: 'DEMAND_ORGANIZATION_QUALIFIER'},
  {dataListType: 'DEMAND_CONTACT_TYPE'},
  {dataListType: 'DEMAND_ACTOR_QUALIFIER'},
  {dataListType: 'DEMAND_CAPABILITY_QUALIFIER'},
  {dataListType: 'DEMAND_PRODUCT_QUALIFIER'},
  {dataListType: 'DEMAND_MARKET_QUALIFIER'},
  {dataListType: 'DEMAND_DATA_QUALIFIER'},
  {dataListType: 'DEMAND_SYSTEM_QUALIFIER'},
  {dataListType: 'DEMAND_CONNECTION_QUALIFIER'},
  {dataListType: 'DEMAND_TECHNOLOGY_QUALIFIER'},
  {dataListType: 'DEMAND_STANDARD_QUALIFIER'},
  {dataListType: 'DEMAND_SKILL_QUALIFIER'},
  {dataListType: 'DEMAND_PHYSICAL_QUALIFIER'},
  {dataListType: 'DEMAND_RELATIONSHIP_QUALIFIER'}
];

var OptionListTypesByKey = {
    systemPriority: 'SYSTEM_PRIORITY'
};


var RelationshipTypes = {};
RelationshipTypes['ASSIGNED_TO'] = 'ASSIGNED_TO';
RelationshipTypes['ASSIGNED_BY'] = 'ASSIGNED_BY';
RelationshipTypes['ACQUIRED'] = 'ACQUIRED';
RelationshipTypes['CONNECTS'] = 'CONNECTS';
RelationshipTypes['CONSUMES'] = 'CONSUMES';
RelationshipTypes['CONTAINS'] = 'CONTAINS';
RelationshipTypes['INCLUDES'] = 'INCLUDES';
RelationshipTypes['IS_A'] = 'IS_A';
RelationshipTypes['IS_NEXT'] = 'IS_NEXT';
RelationshipTypes['IS_WATCHING'] = 'IS_WATCHING';
RelationshipTypes['HAS_A'] = 'HAS_A';
RelationshipTypes['HAS_CHILD'] = 'HAS_CHILD';
RelationshipTypes['HAS_CONTACT'] = 'HAS_CONTACT';
RelationshipTypes['HAS_FIELD'] = 'HAS_FIELD';
RelationshipTypes['HAS_PLL'] = 'HAS_PLL';
RelationshipTypes['HAS_VALUE'] = 'HAS_VALUE';
RelationshipTypes['MANAGER'] = 'MANAGER';
RelationshipTypes['MEMBER'] = 'MEMBER';
RelationshipTypes['OWNS'] = 'OWNS';
RelationshipTypes['PRODUCES'] = 'PRODUCES';
RelationshipTypes['RELATED_TO'] = 'RELATED_TO';
RelationshipTypes['REMEDIATES'] = 'REMEDIATES';
RelationshipTypes['REPLACED_BY'] = 'REPLACED_BY';
RelationshipTypes['REPLACES'] = 'REPLACES';
RelationshipTypes['SOURCE'] = 'SOURCE';
RelationshipTypes['SUPPORTS'] = 'SUPPORTS';
RelationshipTypes['TARGET'] = 'TARGET';
RelationshipTypes['USES'] = 'USES';
RelationshipTypes['PREVIOUS'] = 'PREVIOUS';

var QualifierTypes = {
    RELATED_TO: {displayName: '@@Related_To'},
    OWNS: {displayName: '@@Owns'},
    SUPPORTS: {displayName: '@@Supports'},
    HAS_CHILD: {displayName: '@@Has_Child'},
    USES: {displayName: '@@Uses'}
};


var DOMAIN_DATA_DICT_SEARCH_FIELD_NAMES = {
  CAP: 'domain.bn',
  DAT: 'domain.bn',
  DEM: 'domain.bn',
  PHY: 'domain.bn',
  MKT: 'domain.bn',
  ORG: 'domain.bn',
  PRD: 'domain.bn',
  STR: 'domain.bn',
  SYS: 'domain.bn',
  TEC: 'domain.bn'
};

var TYPE_DATA_DICT_SEARCH_FIELD_NAMES = {
  CAP: 'capabilityType.bn',
  DAT: 'dataType.bn',
  DEM: 'type.bn',
  PHY: 'type.bn',
  MKT: 'type.bn',
  PRD: 'type.bn',
  STR: 'type.bn',
  SYS: 'systemType.bn'
};

var TYPE_DATA_DICT_PROPERTY_KEYS = {
  CAP: 'capabilityType',
  DAT: 'dataType',
  DEM: 'type',
  PHY: 'type',
  MKT: 'type',
  PRD: 'type',
  STR: 'type',
  SYS: 'systemType'
};

var INSIGHT_ENTITY_TYPES = [
  'ADQ', 'AST', 'AUD', 'CIR', 'CRL',
  'LYT', 'TAG', 'WK2'
];
