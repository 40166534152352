/*!
 * bit.base.autocompleteappend.js
 *
 * Positions and manages show/hide of trigger for on-the-fly create at the bottom of autcomplete result lists
 *
 */

$(function () {

    // events delegated to body tag, but ideally, behavior is attached to Wicket components directly (not a trivial change)
    $("body").on('focus', 'input.autocomplete', function () {

        var $autocompleteField = $(this);

        // find the nearest logical parent in the markup that is guaranteed to contain the .autocomplete-append
        var $parent = $autocompleteField.closest('.panel, .field, .single-select, .mx-menu-selector, .mx-linked-entities');
        var $appendage = $parent.find('p.autocomplete-append');

        if ($appendage.length) {

            var autocompleteResultsHeight = 224;
            /* Adjust if autocomplete results height CSS changes */

            var appendIfAutocompletePresent = function () {
                if ($('.wicket-aa-container:visible').length) {

                    var container = $('.wicket-aa-container:visible');
                    var inputTop = $autocompleteField.offset().top;
                    var containerTop= container.offset().top;

                    var top = $autocompleteField.offset().top + autocompleteResultsHeight;
                    // show the menu above the input when search results are above input
                    if(containerTop < inputTop) {
                        top = $autocompleteField.offset().top - autocompleteResultsHeight - 8;
                    }

                    var left = $autocompleteField.offset().left;     // account for border
                    var width = $autocompleteField.width() - 3;          // account for border

                    //BARO-9049 ... in IE8, there appears to be some eventing issues resulting in duplicate appendages showing up when you select a separate company, then try and search for projects.
                    //The invalid appendage has an autocompleteField with a width of 0 (resulting in a width value of -4). Simply only showing the appendage for the proper autocompleteField (where width > 0)
                    if(width > 0) {
                        $appendage.width(width).show().offset({top:top, left:left});
                    }
                } else {
                    $appendage.fadeOut();
                }
            };

            var intervalId = setInterval(appendIfAutocompletePresent, 100);

            // attach blur event only after focus
            $autocompleteField.one("blur", {intervalId:intervalId, $appendage:$appendage}, function (e) {

                e.data.$appendage.fadeOut();
                clearInterval(intervalId);

            });
        }
    });
});


//var reposition = function reposition() {
//    var container = $('.wicket-aa-container:visible');
//    if (container.length) {
//        repositionAutoComplete(container, $(Wicket.Focus.getFocusedElement()));
//    }
//}
//
//// register a window resize event to adjust the height of modals
//var throttledAutoCompleteReposition = _.throttle(reposition, 200);
//$(window).resize(throttledAutoCompleteReposition);



