//static help content

var StaticHelp = {};

//Common
StaticHelp['Common.contentStatus'] = {
    title: '@@Proposed_Edits',
    // TODO: localize
    desc: 'When <strong>On</strong>, changes must be reviewed by an editor before being applied'
};

//Actor
StaticHelp['ACT.name'] = {
    title: "@@Actor_Name",
    desc: "@@Actor_Name_Description"
};
//connection
StaticHelp['ACT.description'] = {
    title: "@@Actor_Description",
    desc: "@@Actor_Description_Description"
};
StaticHelp['CON.description'] = {
    title: "@@Connection_Description",
    desc: '<p>@@Connection_Description_Description</p>'
};
StaticHelp['CON.type'] = {
    title: "@@Connection_Type",
    desc: '<p>@@Connection_Type_Specifies_General_Grouping</p>' +
        '<ol>' +
          '<li>@@Connection_Type_Database_Example</li>' +
          '<li>@@Connection_Type_Direct_Call_Example</li>' +
          '<li>@@Connection_Type_File_Example</li>' +
          '<li>@@Connection_Type_Messaging_Example</li>' +
          '<li>@@Connection_Type_Remote_Invocation_Example</li>' +
          '<li>@@Connection_Type_Web_Service_Example</li>' +
        '</ol>'
};
StaticHelp['CON.pattern'] = {
    title: "@@Connection_Pattern",
    desc: '<p>@@Connection_Pattern_Specifies_Integration_Pattern</p>' +
        '<ol>' +
          '<li>@@Connection_Pattern_API_Call_Description</li>' +
          '<li>@@Connection_Pattern_Client/Server_Description</li>' +
          '<li>@@Connection_Pattern_Database_Description</li>' +
          '<li>@@Connection_Pattern_Program/Module_Description</li>' +
          '<li>@@Connection_Pattern_Publish/Subscribe_Description</li>' +
          '<li>@@Connection_Pattern_Request/Reply_Description</li>' +
          '<li>@@Connection_Pattern_Send/Receive_Description</li>' +
        '</ol>'
};
StaticHelp['CON.dataStructure'] = {
    title: "@@Connection_Data_Structure",
    desc: '<p>@@Connection_Data_Structure_Description</p>'
};
StaticHelp['CON.transport'] = {
    title: "@@Connection_Transport",
    desc: '<p>@@Connection_Transport_Description</p>'
};
StaticHelp['CON.frequency'] = {
    title: "@@Connection_Frequency",
    desc: '<p>@@Connection_Frequency_Description</p>'
};
StaticHelp['CON.sourceFormat'] = {
    title: "@@Connection_Source_Format",
    desc: '<p>@@Connection_Source_Format_Description</p>'
};
StaticHelp['CON.targetFormat'] = {
    title: "@@Connection_Target_Format",
    desc: '<p>@@Connection_Target_Format_Description</p>'
};
// Rule
StaticHelp['CRL.compliancePlan'] = {
    title: "@@Compliance_Plan",
    desc: '<p>@@Compliance_Plan_Description</p>'
};
//system
StaticHelp['SYS.name'] = {
    title: "@@System_Name",
    desc: '<p>@@System_Name_Description</p>'
};
StaticHelp['SYS.description'] = {
    title: "@@System_Description",
    desc: '<p>@@System_Description_Brief_Explanation</p>' +
        '<ul>' +
          '<li>@@System_Description_Purpose</li>' +
          '<li>@@System_Description_Function</li>' +
          '<li>@@System_Description_Benefits</li>' +
          '<li>@@System_Description_Support</li>' +
        '</ul>'
};
StaticHelp['SYS.acronym'] = {
    title: "@@System_Acronym",
    desc: '<p>@@System_Acronym_Description</p>'
};
StaticHelp['SYS.alias'] = {
    title: "@@System_Aliases",
    desc: '<p>@@System_Aliases_Description</p>'
};
StaticHelp['SYS.core'] = {
    title: "@@Core_System",
    desc: '<p>@@Core_System_Description</p>'
};
StaticHelp['SYS.releaseDate'] = {
    title: "@@System_Released_Date",
    desc: '<p>@@System_Released_Date_Description</p>'
};
StaticHelp['SYS.sunsetDate'] = {
    title: "System Sunset Date",
    desc: '<p>@@System_Sunset_Date_Description</p>'
};
StaticHelp['SYS.version'] = {
    title: "@@System_Version",
    desc: '<p>@@System_Version_Description</p>'
};
StaticHelp['SYS.complexity'] = {
  title: "@@System_Complexity",
  desc: '<p>@@System_Complexity_Description</p>'
};
StaticHelp['SYS.criticality'] = {
  title: "@@System_Criticality",
  desc: '<p>@@System_Criticality_Description</p>'
};
