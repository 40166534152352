/*!
 * bit.base.core
 *
 * Core JS for all bIT pages
 *
 */

function ieVersion () {
  var ua = navigator.userAgent.toLowerCase();
  return (ua.indexOf('msie') !== -1) ? parseInt(ua.split('msie')[1]) : false;
}

function isIE8 (){
  return ieVersion() === 8;
}

function isIE9 (){
  return ieVersion() === 9;
}

/* Adds an event to an object. Needed to attach events
 * in older browsers.
 */
function addEvent(obj, type, fn) {
    if (obj.attachEvent) {
        obj['e' + type + fn] = fn;
        obj[type + fn] = function () {
            obj['e' + type + fn](window.event);
        };
        obj.attachEvent('on' + type, obj[type + fn]);
    } else
        obj.addEventListener(type, fn, false);
}

function continueWithoutSaving() {
    // Prompts user for confirmation if it is determined an edit is in progress
    // (Bound to onclick events on links to other pages.)
    if ($(".edit-state").length) {
        if ($(".outline-change-content").length) {
            return confirm('@@You_Have_Made_Changes_Not_On_Outline');
        } else {
            return confirm('@@You_Have_Unsaved_Changes_Continue');
        }
    } else if ($(".sl-edit").length) {
        return confirm('@@You_Have_Unsaved_Changes_Continue');
    } else {
        return true;
    }
}

function continueWithoutSavingCloseOverlay(isTop) {
    // Prompts user for confirmation if it is determined an edit is in progress
    // (Bound to onclick events on links to other pages.)
    if ($(".edit-state").length) {
        if ($(".outline-change-content").length) {
            if (confirm('@@You_Have_Made_Changes_Not_On_Outline')) {
                if (isTop) {
                    closeOverlayTop();
                } else {
                    closeOverlay();
                }
            }

        } else {
            if (confirm('@@You_Have_Unsaved_Changes_Continue')) {
                if (isTop) {
                    closeOverlayTop();
                } else {
                    closeOverlay();
                }
            }

        }
    } else {
        if (isTop) {
            closeOverlayTop();
        } else {
            closeOverlay();
        }
    }
}

/**
 * Add active classes to endpoints and show cnxn diagram
 * @param id ID of the diagram
 */
function prepareCnxn(id){

    function getAdjacentEndgroups(line){

        var src = line.prev('.end-group');
        var trg = line.next('.end-group');
        var i = 0;

        // for sub connections, you may need to climb up to find adjacent endpoints
        while ((!src || !trg || src.length == 0 || trg.length == 0) && i < 100) {
            line = line.parent().closest('.line');
            if (src.length == 0) {
                src = line.prev('.end-group');
            }
            if (trg.length == 0) {
                trg = line.next('.end-group');
            }

            i++;
        }

        return {
            src: src,
            trg: trg
        }
    }

    function getAdjacentLines(endgroup){

        var lines = [];

        if (endgroup.hasClass('src-group') || endgroup.hasClass('mid-group')){
            var topLine = endgroup.next('.line');
            lines.push(topLine);

            var childLine = topLine.find('.line:eq(0)');
            while(childLine.length > 0){
                lines.push(childLine);
                childLine = childLine.find('.line:eq(0)');
            }
        }

        if (endgroup.hasClass('trg-group') || endgroup.hasClass('mid-group')){
            var topLine = endgroup.prev('.line');
            lines.push(topLine);

            var childLine = topLine.find('.line:eq(1)');
            while(childLine.length > 0){
                lines.push(childLine);
                childLine = childLine.find('.line:eq(1)');
            }
        }

        return lines;
    }

    function handleLineMouseEnter(line){
        var endpoints = getAdjacentEndgroups(line);
        endpoints.src.addClass('hover-source');
        endpoints.trg.addClass('hover-target');
        line.data(endpoints);
    }

    function handleLineMouseLeave(line){

        line.data('src').removeClass('hover-source');
        line.data('trg').removeClass('hover-target');
    }

    var cnxn = $('#' + id);
    cnxn.addClass('emphasize-active-cnxn');

    // activate endpoints adjacent to active line
    var activeLine = cnxn.find('.line-wrapper.active').closest('.line');
    var endpoints = getAdjacentEndgroups(activeLine);
    endpoints.src.addClass('active active-source');
    endpoints.trg.addClass('active active-target');
    cnxn.show();

    // on line hover, apply hover state to adjacent endpoints
    var lineWrappers = cnxn.find('.line-wrapper');
    lineWrappers.on('mouseenter', function(){
            handleLineMouseEnter($(this));
        }).on('mouseleave', function(){
            handleLineMouseLeave($(this));
        });

    // on endpoint hover, activate adjacent lines and opposite endpoints
    var endpoints = cnxn.find('.end-group');
    endpoints
        .on('mouseenter', function(){
            var lines =  getAdjacentLines($(this));
            $.each(lines, function(i, line){
                line.find('.line-wrapper:first').addClass('hover');
                handleLineMouseEnter(line);
            });
        }).on('mouseleave', function(){
            var lines =  getAdjacentLines($(this));
            $.each(lines, function(i, line){
                line.find('.line-wrapper:first').removeClass('hover');
                handleLineMouseLeave(line);
            });
        })
}

function loadStyleSheet(styleSheetUrl) {
    if (document.createStyleSheet) {
        document.createStyleSheet(styleSheetUrl);
    } else {
        $('head').append('<link rel="stylesheet" type="text/css" href="' + styleSheetUrl + '"/>');
    }
}

/**
 * Inserts a datepicker
 * @param id The id of the element to attach the datepicker
 */

function datePickerInsert(id) {
    var input = $('#' + id);
    if (input.length) {
        input.datepicker(
            datePickerOptions
        );

        //Set the date format for the date picker to the correct format
        input.datepicker("option", "dateFormat", moment.localeData().longDateFormat('L').toLowerCase());

        // close datepicker on selection
        // prevents out-of-synch issues when wicket replaces the underlying input tag on change but the calendar
        // popover is still open (BARO-12679)
        input.on('change', function(){
            input.datepicker('hide');
        })
    }
}

var datePickerOptions =  {
		language: $LAB.LOCALE_NAME,
	    showOn:'button', buttonImage:'/b/img/calendar.png', buttonImageOnly:true, changeMonth:true, changeYear:true, yearRange:'c-35:c+15'
	};

/**
 * Adds to "edit state" CSS to panel - called on content internal to the panel
 * @param id The id of a content container within the panel
 */
function addEditStateCSS(id) {
    $('#' + id).closest('.assoc-panel').addClass('edit-state');
}

/**
 * Adds to "edit state" CSS to a Form
 * @param id The id of an element withing the form
 */
function addEditStateCSSToParentForm(id) {
    $('#' + id).closest("form").addClass('edit-state');
    $('#' + id).closest("form").find('input[type=submit]').show();
}

/**
 * Removes "edit state" CSS from panel - called on content internal to the panel
 * @param id The id of a content container within the panel
 */
function removeEditStateCSS(id) {
    $('#' + id).closest('.assoc-panel').removeClass('edit-state');
}

/**
 * Formats capability drill-down selector
 * @param id The id of a container of the drill-down selector panel
 */
function prepareDrillDownSelector(id) {
    //console.log("prepareDrillDownSelector: " + id);

    var drillDown = $('#' + id).find('.drill-down-selector');
    var filter = drillDown.find('form');
    var tree = drillDown.find('.tree:first');               // should always be at least 1 .tree
    var lastBranch = tree.find(".tree-branch-last:last");     // 0 to N tree-branch-last's
    var activeListContainer = $('<div class="active-list-container" />');
    var activeList = $('<div class="active-list" />');

    activeListContainer.append(activeList);
    activeListContainer.append(filter);

    // no results in active list, so last branch is selected
    if (lastBranch.find('.tree-node.selected').length) {
        lastBranch.find('.tree-subtree').append(activeListContainer);
    }
    // common case: results in active list
    else if (lastBranch.length) {
        var lastBranchAndSiblings = lastBranch.parent().children();
        lastBranch.parent().append(activeListContainer);
        activeList.append(lastBranchAndSiblings)
    }
    // if root tree has no results
    else {
        tree.find('.tree').append(activeListContainer); //there are actually 2 .tree's
    }

    // initial state, no selection yet
    if ((drillDown.find('.tree-node.selected').length) == 0) {
        drillDown.find('a.root-node').addClass('selected');
    }

    // handle disabled button
    drillDown.find('span.button').replaceWith(function () {
        return '<a class="button disabled">' + $(this).text() + '</a>';
    });

    // waiting...
    function showSpinner() {
        activeList.empty().append('<div class="spinner"/>');
    }

    drillDown.find('a.root-node').add(tree.find('.tree-content a')).click(function () {
        showSpinner();
    });
    drillDown.find('input.checkbox').change(function () {
        showSpinner();
    });

    drillDown.show();  //starts hidden in CSS
}

/* right now just using to handle the buttons */
function prepareFlatSelector(id) {
    var selector = $('#' + id).find('.flat-selector');
    // handle disabled button
    selector.find('span.button').replaceWith(function () {
        return '<a class="button disabled">' + $(this).text() + '</a>';
    });
}

/* DETAILS WITHIN TREE NODES */
function handleTreeNodeDetails(treeID) {

    var treeNodeDetails = $('#' + treeID).find('.tree-node:not(.has-details) span.tree-node-details');

    treeNodeDetails.hide();
    var treeNode = treeNodeDetails.closest('.tree-node').addClass('has-details');
    var treeControl = '<a class="tree-junction-collapsed">&nbsp;</a>';

    // replace dummy tree-holder with anchor
    treeNode.find('span.tree-junction').replaceWith(treeControl);

    // add click function to new link
    treeNode.find('.tree-junction-collapsed').click(function () {
        var t = $(this);
        if (t.hasClass('tree-junction-collapsed')) {
            t.attr('class', 'tree-junction-expanded');
        } else {
            t.attr('class', 'tree-junction-collapsed');
        }
        t.closest('.tree-branch').toggleClass('bracket');
        t.closest('.tree-branch').find('.tree-junction-expanded').toggleClass('no-line');
        t.closest('.tree-node').find('.tree-node-details').toggle();
        t.closest('.tree-node').find('.icon.team').toggle();

        /* Fix for BARO-4928
         * Tree nodes aren't expanding height-wise around the list of people in the
         * org chart for IE8.  We explicitly set the height instead.
         */
        if (isIELessThan(9) && (t.parents('#orgTree') || t.parents('#orgChart'))) {
            var branch = t.closest('.tree-branch');
            var node = t.closest('.tree-node');
            branch.css('height', node.height());
        }
    });
}

/* Detect and reposition autocomplete lists within
 * scrollable containers
 */
function detectAutoComplete() {

    function repositionAutoComplete(container, input) {

        var
            inputOffset = input.offset(),
            inputBottom = inputOffset.top + input.outerHeight(),
            inputLeft = inputOffset.left,
            containerTop = container.offset().top,
            containerBottom = container.offset().top + container.height() + 20,
            windowBottom =  $(window).scrollTop() + $(window).height();

        if (Math.abs(containerTop - inputBottom) > 1) {
            container.css({
                top: inputBottom + 1,
                left: inputLeft
            });
        }
        if(containerBottom > windowBottom) {
            // move container above input
            container.css({
                top: input.offset().top - container.height()
            });
        }

    }

    var input = $(Wicket.Focus.getFocusedElement());
    if (input.hasClass('autocomplete')) {
        // Go through parents and check for overflow: auto and hidden
        // (FF returns an empty string for "auto" on occasion - see BARO-7223)
        var parent = input.parent();
        var parentOverflow = parent.css('overflow');

        while (parent != null && parent[0].tagName.toLowerCase() != 'body'
            && parentOverflow != 'auto'
            && parentOverflow != 'hidden'
            && parentOverflow != '') {

            parent = parent.parent();
            parentOverflow = parent.css('overflow');
        }

        //if (parent != null && parent[0].tagName.toLowerCase() != 'body') {
            var container = $('.wicket-aa-container:visible');
            if (container.length) {
                repositionAutoComplete(container, input);

                container.find('li').each(function () {
                    addEvent(this, "mouseover", function () {
                        repositionAutoComplete(container, input);
                    });
                });

            }

            // Hide autocomplete list when scrollable parent is scrolled
            parent.scroll(function () {
                container.hide();
            });

        //}
    }
}

function prepareLabeledDropDown(id) {

    console.log('prepareLabeledDropdown for id ' + id);

    // can be called on one labeledDropDown or a group of them
    var labeledDropDown = $('#' + id).findWithSelf('.control-group');
    labeledDropDown.show().each(function(){
        var label = $('<label></label>');   // generate label markup dynamically
        var container = $(this);
        var dropDown = container.find('select');
        label.text(dropDown.find('option:selected').text());
        dropDown.before(label);

        // set the initial container width
        container.width(label.outerWidth());

        // update the container width on change
        dropDown.on('change', function(){
            label.text(dropDown.find('option:selected').text());;
            container.width(label.outerWidth());
        });
    });
}

function moveRootPickerToControlBar(rootPickerContainerId) {
    var container = $('#' + rootPickerContainerId).closest('main');
    var controlBarList = container.find('.control-bar:first ul');
    var rootPickerDropDown = container.find('.root-picker .labeled-drop-down');
    var rootPickerLegend = container.find('.root-picker legend');

    // remove any previously moved rootPickers
    controlBarList.find('.labeled-drop-down.from-rootpicker').closest('li').remove();

    var i = 0;
    rootPickerDropDown.each(function(){
        $(this).find('.labeled-drop-down').removeClass('.labeled-drop-down');  // this will be added to the parent form in the new location
        var newListItem = $('<li><form class="labeled-drop-down from-rootpicker"><fieldset></fieldset></form></li>');
        controlBarList.prepend(newListItem);
        newListItem.find('fieldset').append(rootPickerLegend).append(rootPickerDropDown.children());
        newListItem.attr('id', 'new-'+i);
        prepareLabeledDropDown('new-'+i);
        rootPickerLegend.show();
    });
}

/**
 * Adds a tooltip to an element with .attachment (could be used more generically)
 * @param id The id of a container
 */
function prepareDocumentPreviewTooltip(id) {
    $('#' + id).findWithSelf('.attachment').each(function(){
        new ToolTip($(this), $(this).siblings('.tooltip-content'), 'large');
    });
}


function doOnDomReady() {

        /* Register a post ajax handler to reposition autocomplete list if necessary */
        if (typeof Wicket != "undefined" && 'Ajax' in Wicket) {
            Wicket.Ajax.registerPostCallHandler(detectAutoComplete);
        }

        // Confirm dialog for data extracts downloads
        $('#admin-data-extracts-page a.data-extract').click(function () {
            return confirm('@@Clicking_OK_Will_Create_Extract_1' + "\n\n" + '@@Clicking_OK_Will_Create_Extract_2');
        });


        $("input.autocomplete").each(function () {
            if ($(this).val() === '') {
                $(this).addClass('empty').val($(this).attr("title"));
            }
        });

        /*
         * Used to set a maxlength on any text area on the page. If a "maxlength"
         * attribute is specified on the textarea tag this script will enforce it.
         */
        $("textarea[maxlength]").keypress(function (event) {
            var key = event.which;

            // all keys including return.
            if (key >= 32 || key == 13) {
                var maxLength = $(this).attr("maxlength");
                var length = this.value.length;
                if (length >= maxLength) {
                    event.preventDefault();
                }
            }
        });
}


$(document).ready(function () {

    // ViewDetails
    // TODO: @deprecated remove
    $('body').on("click", "a.viewdetails", function (e) {
        e.preventDefault();

        // hide all currently open overlays
        $(".detail-overlay:visible").hide();

        // if it's not an AJAX link, find the overlay and show it
        if (!$(this).hasClass("ajaxified")) {
            $(this).parent().find('.detail-overlay').show();
        }

        // close all open overlays when a click occurs anywhere
        // once-only event, removes itself
        $("body").one("click", function (event) {
            $(".detail-overlay:visible").each(function () {
                $(this).hide();
                // Remove any temporary styling changes made when opening (to fit without overflow; see positionDetailOverlay())
                $(this).closest('.table-container, .table-wrapper').attr('style', '');
            });
        });
    });

    // AutoComplete Text Fields
    // display Title attribute as user prompt when field is empty
    // expects initial Value = TItle and class = 'empty' on HTML load
    $('body').on("focus", "input.autocomplete", function () {
        if ($(this).hasClass('empty')) {
            $(this).removeClass('empty').val('');
        }
    }).on("blur", "input.autocomplete", function () {
            if ($(this).val() === '') {
                $(this).addClass('empty').val($(this).attr("title"));
            }
        });
});


/**
 * Handles the hover overlay within data tables
 * @param id The table ID
 */
function handleHoverDetails(id) {
    $('#' + id).find('.hoverdetails').mouseenter(function () {
        $('.detail-overlay:visible').parent().css('top', '0');
        $(".detail-overlay:visible").hide();
        var parent = $(this).parent();
        var overlay = parent.find('div.detail-overlay');
        overlay.show().click(function (event) {
            event.preventDefault();
        });

        positionDetailOverlay(overlay.parent(), id, $(this).height(), true);

        $("body").one('click', function () {
            overlay.hide();
            overlay.parent().css('top', '0');
        });
    });
}

function positionDetailOverlay(overlayContainer, containerId, additionalOffset, allowOverflowRight) {
    var overlay = overlayContainer.find('.detail-overlay');
    var container = $('#' + containerId);

    // Detect if overlay can fit in container
    var containerTop = container.offset().top;
    var overlayTop = overlay.offset().top;
    var containerLeft = container.offset().left;
    var containerRight, overlayRight, offset;

    // If it is, show overlay below trigger
    if (overlayTop < containerTop) {
        var parentTop = overlayContainer.offset().top;
        offset = parentTop - overlayTop + additionalOffset;
        overlayContainer.css('top', offset + 'px');
    }
    
    // If contained in a table-wrapper, see if space needs to be added to the bottom
    if (overlayContainer.closest('.table-wrapper').length ) {
        var tableWrapper = overlayContainer.closest('.table-wrapper');
        var tableWrapperBottom = tableWrapper.offset().top + tableWrapper.height();
        var overlayContainerBottom = overlayContainer.offset().top + overlayContainer.height();

        // Remove any existing style overrides
        tableWrapper.attr('style', '');

        // Wait for re-layout before measuring spacing
        setTimeout(function(){
            if (overlayContainerBottom > tableWrapperBottom) {
                offset = overlayContainerBottom - tableWrapperBottom + 20;
                var existingMarginBottom = parseFloat(tableWrapper.css('margin-bottom')) || 0;
                tableWrapper.css({'padding-bottom': offset + 'px','margin-bottom': '-' + (offset - existingMarginBottom) + 'px'});
            }
        });

        // Note: the body click handler that closes the contaner also replaces the original padding and margin
  }

    if (!allowOverflowRight) {
        // See if overlay needs to move left
        containerRight = containerLeft + container.width();
        overlayRight = overlay.offset().left + overlay.width();

        if (overlayRight > containerRight) {
            offset = overlayRight - containerRight + 10;
            overlay.css('left', '-' + offset + 'px');

            // Chrome has an occasional issue properly displaying this
            // element. Giving it a new height causes it to show it. We then
            // reduce the height immediately.
            overlayContainer.css('height', '1px');
            overlayContainer.css('height', '0');
        }
    }
}

/**
 * A click of a table header column will click any containing link
 * Used for sorting
 * @param id The ID of the table
 */
function handleTableHeaderClick(id) {
    $('#' + id + ' th a').click(function (event) {
        event.stopPropagation();
    });

    $('#' + id + ' th').click(function () {
        $(this).find('a').click();
    });
}

/**
 * Handle assoc-path detail toggle
 * @param id The ID of the container to search for assoc paths
 */
function handleAssocPath(id) {
    var assocPaths = $('#' + id).find('.assoc-path');
    var panel = assocPaths.closest('.panel, .new-panel');
    if (!(panel.hasClass('show-details') || panel.hasClass('hide-details'))) {
        panel.detailToggle();
    }
}

/**
 * Creates a horizontal scrollable list
 * @param id The list container
 */
function prepareScrollableList(id) {
    $('#' + id).scrollableList(true);
}

/**
 * Truncates lists within a panel
 * @param id The id of the container to search for the list. It will traverse up
 * and down the DOM from the container looking for a panel.
 */
function truncateList(id) {
    var elem = $('#' + id);
    elem.closest('.panel,.new-panel').add(elem.find('.panel,.new-panel')).truncateList();
}

/**
 * Prepares a link to open up a second modal window
 * @param id The ID of the link
 */
function prepareTopModalWindow(id) {
    // console.log("prepareTopModalWindow called on " + id);
    $('body').css('overflow', 'hidden');
    $('#' + id).modalWindow(true, true);
}

/**
 * Moved the content specified by the id to the overlay holder section.
 */
function moveToOverlayHolder(id, isTop) {
    if (isTop) {
        $('#' + id).appendTo($('.wicket-overlay-top-holder'));
    } else {
        $('#' + id).appendTo($('.wicket-overlay-holder'));
    }
}

/**
 * Prepares a link to open up a modal window
 * @param id The ID of the link
 */
function prepareModalWindow(id) {
    // console.log("prepareModalWindow called on " + id);
    $('body').css('overflow', 'hidden');
    $('#' + id).modalWindow(false, true);
}

/**
 * Handle delete buttons
 * @param id The form ID the delete buttons exist on
 */
function handleDeleteButtons(id) {
    var sel =  ".button.delete:not(.handle-delete-run)";
    $('#' + id).find(sel).each(function () {

        $(this).addClass('handle-delete-run');

        // setting "disabled" on a submit button when it is clicked halts form
        // submission in some browsers - delay lets it submit
        var deleteFunction = "$('#" + id + "').find('.button.delete').attr('disabled', 'disabled').attr('value','Deleting...').addClass('working').closest('.button-wrapper').addClass('working')";

        $(this).click(function () {
            var handled = false;

            if ($(this).hasClass("confirm-position-delete")) {
                handled = true;
                var confirmed = confirm('@@All_Associated_Org_Etc_Will_Be_Deleted_From_Profile_Continue');
                if (!confirmed) {
                    return false;
                }
            }
            if ($(this).hasClass("confirm-participation-delete")) {
                handled = true;
                var confirmed = confirm('@@All_Associated_Roles_Etc_Will_Be_Deleted_From_Profile_Continue');
                if (!confirmed) {
                    return false;
                }
            }
            if ($(this).hasClass("confirm-circle-delete")) {
                handled = true;
                var confirmed = confirm('@@Permanently_Delete_Circle_Continue');
                if (!confirmed) {
                    return false;
                }
            }
            if ($(this).hasClass("confirm-discussion-delete")) {
                handled = true;
                var confirmed = confirm('@@Permanently_Delete_Discussion_Continue');
                if (!confirmed) {
                    return false;
                }
            }
            if ($(this).hasClass("confirm-customfield-delete")) {
                handled = true;
                var confirmed = confirm('@@Permanently_Delete_Custom_Field_Continue');
                if (!confirmed) {
                    return false;
                }
            }
            if ($(this).hasClass("confirm-project-delete")) {
                handled = true;
                var confirmed = confirm('@@Permanently_Delete_Project_Continue');
                if (!confirmed) {
                    return false;
                }
            }

            if (!handled) {
                // Get entity type
                var type = $(this).val().replace(/Delete/, '').toLowerCase();
                var confirmed = confirm(String.format("@@Permanently_Delete_Entity_Continue", type));
                if (!confirmed) {
                    return false;
                }
            }

            var t = setTimeout(deleteFunction, 50);
        });
    });
}

/**
 * Generically prepend an onclick event (via onclick HTML attribute) with a confirm dialog.  If the user says 'nope',
 * the original onclick handler is not executed.
 * @param id, message
 */
function prependOnclickAttrWithConfirmDialog (id, message){
    var item = $('#' + id);
    var onclick = item.attr('onclick');
    var prefix = "var c = confirm('" + message + "'); if (!c) return false;";
    item.attr('onclick', prefix + onclick);
}

/**
 * Toggles an element off and on
 * @param togglerId The id of the link to trigger the toggle
 * @param toggleeId The id of the element to toggle
 */
function toggle(togglerId, toggleeId) {
    $('#' + togglerId).click(function (e) {
        e.preventDefault();
        $('#' + toggleeId).toggle();
    });
}

function confirmDialog(linkId, message) {
    $('#' + linkId).click(function (e) {
        return confirm(message);
    });
}

// Check for IE 6 and 7
function isIELessThan(lessThanVer) {
    if (navigator.appName == 'Microsoft Internet Explorer') {
        var rv = lessThanVer;
        var ua = navigator.userAgent;
        var re = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
        if (re.exec(ua) != null)
            rv = parseFloat(RegExp.$1);

        if (rv < lessThanVer) return true;
    }

    return false;
}

function closeOverlay() {
    $('div.overlay-holder').hide();
    $('div.overlay-holder').find('.overlay').empty();
    $('.overlay-modal-bg').remove();
    $('.wicket-overlay-holder').find('.wicket-overlay').empty();
    $('body').css('overflow', 'visible');
}

function closeOverlayTop() {
    $('div.overlay-top-holder').hide();
    $('div.overlay-top-holder').find('.overlay').empty();
    if ($('div.overlay-holder:visible').length == 0) {
        $('body').css('overflow', 'visible');
    }
    addOrUpdateScreens();
}

function positionFixedButtonOverlay(id) {
    var buttons = $('#' + id).children('.buttons');
    var overlayHeader = buttons.closest('.overlay').find('.overlay-header');
    var top;
    var intervalId;

    // this can be called before the overlay is visible, i.e. it is still opening,
    // which messes up the height measurement -- BARO-6980
    var checkForVisibleOverlay = function () {
        if (overlayHeader.is(":visible")) {

            top = overlayHeader.outerHeight() - 10;
            buttons.css('top', top).show();

            if (intervalId) {
                clearInterval(intervalId);
            }

        } else {
            if (!intervalId) {
                intervalId = setInterval(checkForVisibleOverlay, 100);
            }
        }
    };

    checkForVisibleOverlay();
}

/**
 * Flattens tree starting at level 3
 * @param id The id of the container that contains the tree.
 */
function flattenTree(id) {

    // trim off the extra .tree wrappers (there are at least two)
    var container = $('#' + id);
    var trees = container.find('.tree .tree-branch:first-child').closest('.tree').clone();
    trees.appendTo(container);
    trees.attr('class', 'box-diagram');
    container.find('.tree').remove();     // comment this out to see the "before" markup

    trees.each( function() {
        var tree = $(this);

        // flatten any nodes below level 2 into a list
        tree.find('> .tree-branch > .tree-subtree > .tree-branch').each ( function () {
            var flattenedNodeList = [];
            var level2 = $(this);
            var treesToFlatten = level2.find('.tree-subtree');
            treesToFlatten.each ( function () {
                // create a list of flattened nodes
                var flattenedNodes = $(this).find('> .tree-branch > .tree-node > .tree-content');
                flattenedNodes.each( function() {
                    // filter out nodes that are only here because they are a parent of the capability
                    var assocPath = $(this).find('.assoc-path').text();
                    if (assocPath.indexOf('directly') != -1 || assocPath.indexOf('subsystem') != -1) {
                        flattenedNodeList.push($(this));
                    }
                });
            });

            // add the list to the level2 tree.node
            if (flattenedNodeList.length > 0) {
                var list = $('<ul/>');
                level2.append(list);
                for (var i = 0; i < flattenedNodeList.length; i++) {
                    var item = $('<li/>');
                    list.append(item);
                    item.append(flattenedNodeList[i].children());
                }
            }

            // remove the flattend trees.
            treesToFlatten.each ( function () {
                $(this).remove();
            });


        });

        // now convert the markup into box diagram markup
        tree.find('.tree-junction, .tree-junction-expanded, .tree-junction-collapsed').remove();
        tree.find('.tree-subtree').attr('class', 'child');
        tree.find('.tree-node').children(':first-child').unwrap();  // tree-node wrapper can be removed
        tree.find('.tree-branch').each(function(){
            $(this).attr('class', 'box');
            var label = $('<label />');
            var treeContent = $(this).find('> .tree-content');
            label.append(treeContent.children());

            treeContent.remove();
            $(this).prepend(label);
         });
    });


    // add .expanded class if child boxes present - diagrams are not interactive
    trees.find('.box').has('.box').addClass('expanded');

    trees.show();

    // masonry helps _a little_ with packing the trees in more efficiently
    container.isotope({
        layoutMode:'fitRows'
    });
}

/**
 * Checks to see if an absolutely positioned element lies outside the body's bottom boundary
 * @param id The id of the component to check
 */
function checkBoundaries(id) {
    var body = $('body');
    var container = $('#' + id);
    var i = 0;

    while (container != null && container.css('position') != 'absolute' && container != body && i < 100) {
        container = container.parent();
        i ++;
    }

    if (container != null) {
        var containerBottom = container.offset().top + container.outerHeight();

        if (body.height() < containerBottom) {
            body.height(containerBottom);
        }
    }
}

/**
 * This function is called via a wicket behavior after the dom is ready.
 */
function setGlobalSearchFocus() {
    /* MOVE CURSOR TO SEARCH BOX */
    $(".header input:text").focus(function () {
        $(this).addClass("focus");
    });
    $(".header input:text").blur(function () {
        $(this).removeClass("focus");
    });
    $(".header input:text").focus();
}

function addOrUpdateScreens() {

    var lastVisibleModal = $(".modal:visible").last();

    // grab all existing screens
    var oldScreens = $('.modal-backdrop');

    if (lastVisibleModal.length) {
    	
        var newModel = $('<div class="modal-backdrop"></div>');
        newModel.css({
            zIndex:(lastVisibleModal.css("z-index") - 1)
        });
        lastVisibleModal.before(newModel);
    }

    // don't remove old screens until new one is created to avoid screenless moment
    oldScreens.remove();

}

function selectDocumentPageTab(pageNumber) {
    $('.document-page-list li').each(function () {
        if ($(this).find('a').attr('data-id') != pageNumber) {
            $(this).removeClass('active');
        } else {
            $(this).addClass('active');
        }
    });
}

function groupTableData(tableId) {
    var currentGroupId = "";
    var tableHeaderRow = $('#'+tableId).find('tr')[1];
    var numberOfColumns = $(tableHeaderRow).find("th").length;
    $('#'+tableId).find("td[data-group-id]").each(function() {
        var thisGroupId = $(this).attr("data-group-id");
        //$($(this).parent("tr")[0]).hide();
        if (thisGroupId != currentGroupId) {
            // insert a new row with group details
            var parentRow = $(this).parent("tr")[0];
            var groupData = $('#'+tableId).find("td[data-group-id=" + thisGroupId + "]");
            var count = groupData.length;
            var groupValue = $(groupData[0]).html();
            $(parentRow).before("<tr class='group'><td colspan=\'" + numberOfColumns + "\'>" + groupValue + "</td></tr>");
            currentGroupId = thisGroupId;
        }
        $(this).html("");
    });
}

function prepareHelpTooltip(id) {
    var
        elem = $('#'+id),
        link = elem.find('.help-link'),
        content = elem.find('.help-content').html(),
        placement = (elem.closest('.section-inspector').length) ? 'left' : 'right';

    link.tooltip({
        title: content,
        html: true,
        placement: placement,
        container: 'body'
    })
}

// TODO: generalize the scriptloading pattern...
function initQueryBuilderEditField(selector) {
    var editor = new QueryBuilderEditor(selector);
    editor.init(); 
}
function initQueryBuilderViewField(selector) {
    var viewer = new QueryBuilderViewer(selector);
    viewer.init();
}

//Function used for string format
//replaces {#} with equivalent parameter
String.format = function(format) {
    var args = Array.prototype.slice.call(arguments, 1);
    return format.replace(/{(\d+)}/g, function(match, number) {
        return typeof args[number] != 'undefined'
            ? args[number]
            : match
            ;
    });};

// Used in box diagrams
_.mixin({
    recursive: function(obj, opt, iterator) {
        function recurse(obj) {
            iterator(obj);
            _.each(obj.children, recurse);
        }
        recurse(obj);
    }
});
