/*!
 * bit.base.maps.js
 *
 * Load, view, and navigate maps
 *
 */

var mapsUI = new function () {
    var
        wrapperWidth = 0,
        wrapperHeight = 0,
        wrapperBufferHorz = 0,
        wrapperBufferVert = 0,
        containerWidth = 0,
        containerHeight = 0,
        mapContainer,
        mapWrapper,
        lineHighlightColor = '#333',
        lineHoverColor = '#F90',
        current = { x: 0, y: 0 },
        initial = { x: 0, y: 0 },
        currentRequest = 0,
        zoom = 1,
        ZOOMING = false,
        DRAGGING = false,
        options,
        requestTimer,
        backgroundRequestEnabled = false,
        supportsSvg = document.implementation.hasFeature("http://www.w3.org/TR/SVG11/feature#BasicStructure", "1.1");

        // use getters for these, not the var's directly
        var _defs, _parentSvg;

    this.setup = function (opts) {
        var defaults = {
            overlayType: '',
            bn: ''
        };

        options = jQuery.extend(defaults, opts);
        requestTimer;

        mapContainer = $('#map-container');
        mapWrapper = mapContainer.find('#map-wrapper');
        wrapperWidth = mapWrapper.width();
        wrapperHeight = mapWrapper.height();
        containerWidth = mapContainer.width();
        containerHeight = mapContainer.height();
        wrapperBufferHorz = 100;
        wrapperBufferVert = 100;

        if (supportsSvg) {
            handleMapEvents();
            requestTimer = setTimeout(function () {
                restartTimer();
            }, 1000);
            makeRequest(current, current, zoom, true, '');
            handleMouseWheel();
        } else {
            mapWrapper.append('<div class="map-message">Your browser does not support SVG.</div>');
        }
    };

    // eventually this should dynamically refl  ect tenant-defined system types
    var iconsUrl = $LAB.imgDirUrl() + 'icons/';
    this.getSystemTypes = function() {
        return {
            "PAP":["Package Application", iconsUrl + "icon-app-large.png"],
            "MPA":["Modified Packaged Application", iconsUrl + "icon-app-large.png"],
            "CDB":["Custom Database", iconsUrl + "icon-db-large.png"],
            "PDB":["Package Database", iconsUrl + "icon-db-large.png"],
            "MOD":["Module", iconsUrl + "icon-gear-large.png"],
            "PRO":["Process", iconsUrl + "icon-gear-large.png"],
            "BAT":["Batch Job", iconsUrl + "icon-gear-large.png"],
            "INF":["Infrastructure", iconsUrl + "icon-gear-large.png"],
            "COM":["Composite", iconsUrl + "icon-composite-large.png"],
            "CPY":["Company", iconsUrl + "icon-org-large.png"],
            "CAP":["Custom Application", iconsUrl + "icon-app-large.png"]
        }};

    /* SVG utilities */
    // insert svg node (from http://www.benknowscode.com/2012/09/using-svg-elements-with-jquery_6812.html)
    // NOTE: does not wrap in jQuery
    function SVG(tagName, id) {
        var svgTag = document.createElementNS('http://www.w3.org/2000/svg', tagName);
        if (id) {
            svgTag.setAttribute('id', id);
        }
        return svgTag;
    }

    // insert svg image node http://stackoverflow.com/questions/6701705/
    function svgImage(href) {
        var image = SVG('image');
        image.setAttributeNS('http://www.w3.org/1999/xlink', 'href', href);
        return image;
    }

    // add a reusable filter
    function addFilter(filterId, attrsObj) {
        var filter = SVG('filter', filterId);
        $.each(attrsObj, function (attrName, value) {
            filter.setAttribute(attrName, value);
        });
        $(getDefs()).append(filter);
        return filter;
    }

    // set viewBox attribute on main svg node (can't use jQuery because it auto-lowercases to 'viewbox')
    function setViewBox(attrString) {
        $(getParentSvg()).setSvgAttr("viewBox", attrString);
    }

    function getParentSvg() {
        if (typeof _parentSvg === 'undefined') {
            _parentSvg = mapContainer.find('svg:first')[0];
        }
        return _parentSvg;
    }

    // <defs> contains global definition for reusable stuff (
    function getDefs() {
        if (typeof _defs === 'undefined') {
            _defs = SVG('defs');
            getParentSvg().appendChild(_defs);
        }
        return _defs;
    }

    function addDropShadowFilter() {
        // the filter becomes a DOM node, additional filters become it's children
        var dropShadow = $(addFilter('DropShadowFilter', {
            x: -10,
            y: -10,
            width: '150%',
            height: '150%',
            filterUnits: 'userSpaceOnUse'
        }));

        // scoot shadow over and down
        var offset = $(SVG('feOffset'));
        offset.setSvgAttr('result', 'offsetOut');
        offset.setSvgAttr('in', 'SourceGraphic');
        offset.setSvgAttr('dx', 2);
        offset.setSvgAttr('dy', 2);
        dropShadow.append(offset);

        // transform icon shape into black silhouette (I think?)
        var colorMatrix = $(SVG('feColorMatrix'));
        colorMatrix.setSvgAttr('result', 'colorMatrixOut');
        colorMatrix.setSvgAttr('in', 'offsetOut');
        colorMatrix.setSvgAttr('type', 'matrix');
        colorMatrix.setSvgAttr('values', [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 1, 0]
        ]);
        dropShadow.append(colorMatrix);

        // blur the black shape
        var gaussianBlur = $(SVG('feGaussianBlur'));
        gaussianBlur.setSvgAttr('result', 'gaussianBlurOut');
        gaussianBlur.setSvgAttr('in', 'colorMatrixOut');
        gaussianBlur.setSvgAttr('stdDeviation', 2);
        dropShadow.append(gaussianBlur);

        // blend it all together
        var blend = $(SVG('feBlend'));
        blend.setSvgAttr('restult', 'blendOut');
        blend.setSvgAttr('in', 'SourceGraphic');
        blend.setSvgAttr('in2', 'gaussianBlurOut');
        blend.setSvgAttr('mode', 'normal');
        dropShadow.append(blend);
    }

    function handleMouseWheel() {
        mapWrapper.mousewheel(function (event, delta) {

            event.preventDefault();

            // throttled to every half second
            if (!ZOOMING) {
                ZOOMING = true;
                setTimeout(function(){
                    ZOOMING = false;
                }, 500);

                var
                    viewBox = getParentSvg().getAttribute('viewBox').split(' '),
                    left = Number(viewBox[0]),
                    top = Number(viewBox[1]),
                    animDur = 200,
                    zoomFactor = .5,
                    currentZoom,
                    currentLeft,
                    currentTop,
                    currentHeight,
                    currentWidth;

                if (zoom == 1 && delta < 0) {
                    zoom = 2;

                    // Expand map width;
                    containerWidth = parseInt(containerWidth * 1.5, 10);
                    containerHeight = parseInt(containerHeight * 1.5, 10);

                    // Hide anything that only shows at level 1
//                    $(getParentSvg()).find('[z="1"]').each(function () {
//                        $(this).hide();
//                    });

                    // animate the zoom out
                    $(getParentSvg()).animate({tabIndex: 0},  // fake attr to make jquery happy
                        {
                            duration: animDur,
                            step: function (now, fx) {
                                // fx.pos progresses from 0 --> 1
                                currentZoom = 1 + (zoomFactor * fx.pos);
                                currentLeft = left - (100 * fx.pos);//(currentZoom * Number(wrapperBufferHorz));
                                currentTop = top - (100 * fx.pos); //(currentZoom * Number(wrapperBufferVert));
                                currentWidth = currentZoom * wrapperWidth;
                                currentHeight = currentZoom * wrapperHeight;
                                setViewBox(currentLeft + " " + currentTop + " " + currentWidth + " " + currentHeight);
                            }
                        });

                    // Fill in any empty spots on the edges
                    makeBackgroundRequest(current, current, zoom);
                }
                else if (zoom == 2 && delta > 0) {
                    zoom = 1;

                    // Restrict map width;
                    containerWidth = parseInt(containerWidth / zoomFactor, 10);
                    containerHeight = parseInt(containerHeight / zoomFactor, 10);

                    // Show anything that shows at level 1
//                    $(getParentSvg()).find('[z="1"]').each(function () {
//                        var object = $(this);
//                        if (!object.data("startsHidden")) $(this).show();
//                    });

                    // animate the zoom in
                    $(getParentSvg()).animate({tabIndex: 0},  // fake attr to make jquery happy
                        {
                            duration: animDur,
                            step: function (now, fx) {
                                // fx.pos progresses from 0 --> 1
                                currentZoom = 1 + zoomFactor - (zoomFactor * fx.pos);
                                currentLeft = left + (100 * fx.pos);//(currentZoom * Number(wrapperBufferHorz));
                                currentTop = top + (100 * fx.pos);//(currentZoom * Number(wrapperBufferVert));
                                currentWidth = currentZoom * wrapperWidth;
                                currentHeight = currentZoom * wrapperHeight;
                                setViewBox(currentLeft + " " + currentTop + " " + currentWidth + " " + currentHeight);
                            }
                        });
                }

            }


        });

    }

    function centerMapOnInit() {
        mapWrapper.css({ 'margin-left': -1 * wrapperBufferHorz + 'px', 'margin-top': -1 * wrapperBufferVert + 'px', 'left': '0', 'top': '0' });
    }

    function restartTimer() {
        backgroundRequestEnabled = true;
        requestTimer = setTimeout(function () {
            restartTimer();
        }, 1000);
    }

    function makeRequest(currentLocation, lastLocation, currentZoom, needCenter, system) {

        showMapSpinner();
        currentRequest = currentRequest + 1;

        var url = '/b/mapresponse';
        if (options.overlayType != '') {
            url += "/" + options.overlayType + "/" + options.bn;
        }

        $.get(url, { currentLocation: currentLocation, lastLocation: lastLocation, currentRequest: currentRequest, zoom: currentZoom, svg: true, mapWidth: containerWidth, mapHeight: containerHeight, horzBuffer: wrapperBufferHorz, vertBuffer: wrapperBufferVert, returnCenter: needCenter },
            function (data) {

                if (data) {
                    var response = data;
                    var responseLocation = { x: response.currentLocation[0], y: response.currentLocation[1] };
                    var overlayEnabled = response.overlayEnabled;
                    var noSystems = response.noSystems;

                    var map = response.content;

                    if (supportsSvg) {
                        mapWrapper.draggable({ stop: function (event, ui) {
                            DRAGGING = false;
                            var deltaLeft = ui.position.left;
                            var deltaTop = ui.position.top;

                            var lastLocation = current;
                            current = { x: responseLocation.x - deltaLeft, y: responseLocation.y - deltaTop };

                            makeRequest(current, lastLocation, zoom, false, '');
                        },
                            drag: function (event, ui) {
                                DRAGGING = true;

                                var dragRight = ui.position.left;
                                var dragBottom = ui.position.top;

                                // Make a request when hitting a boundary
                                if (Math.abs(dragRight) > wrapperBufferHorz || Math.abs(dragBottom) > wrapperBufferVert) {
                                    var newLocation = { x: responseLocation.x - dragRight, y: responseLocation.y - dragBottom };
                                    makeBackgroundRequest(newLocation, current, zoom);
                                }

                                mapWrapper.css({ 'margin-top': -1 * wrapperBufferVert - dragBottom + 'px', 'margin-left': -1 * wrapperBufferHorz - dragRight + 'px' });

                                if (supportsSvg) {
                                    var viewBoxOffsetX = zoom == 1 ? 0 : -1 * wrapperBufferHorz;
                                    var viewBoxOffsetY = zoom == 1 ? 0 : -1 * wrapperBufferVert;
                                    setViewBox((viewBoxOffsetX - dragRight) + " " + (viewBoxOffsetY - dragBottom) + " " + (wrapperWidth + (zoom - 1) * wrapperWidth / 2) + " " + (wrapperHeight + (zoom - 1) * wrapperHeight / 2));
                                }
                            }
                        });
                    }

                    if (needCenter) {
                        current = responseLocation;
                    }

                    // Center map if this is the first request
                    if (mapWrapper.find('svg').length == 0) {
                        initial = current;
                        centerMapOnInit();
                    }

                    if (supportsSvg) {
                        addMap(map, overlayEnabled);
                        hideMapSpinner();
                    }

                    if (noSystems) {
                        showMapNoSystemsFound();
                    }
                } else {
                    console.error('Error: Data returned from request: ' + url + ' was null');
                }

            });
    }


    function addMap(map, overlayEnabled, noSystems) {
        //console.log("addMap called. map="+map+" overlayEnabled="+overlayEnabled+" noSystems="+noSystems);
        var firstRequest = false;
        var elementHolder;

        if (mapWrapper.find('svg').length == 0) {
            firstRequest = true;
            mapWrapper.html(map);
            addDropShadowFilter();
            elementHolder = $(getParentSvg());
        }
        else {
            elementHolder = map;
        }

        // Add and modify lines
        $(elementHolder).children('line').each(function () {
            var newX1 = $(this).attr('bx1') - initial.x + wrapperBufferHorz;
            var newX2 = $(this).attr('bx2') - initial.x + wrapperBufferHorz;
            var newY1 = $(this).attr('by1') - initial.y + wrapperBufferVert;
            var newY2 = $(this).attr('by2') - initial.y + wrapperBufferVert;

            $(this).attr({ x1: newX1, x2: newX2, y1: newY1, y2: newY2 });

            var currentColor = $(this).attr('fill');
            $(this).data("stroke", currentColor);

            $(this).data("startsHidden", isLineHidden($(this)));

            if (overlayEnabled && !$(this).data('visited')) {
                opacity = .1;
            }

            if (zoom > $(this).attr('z')) {
                $(this).hide();
            }

            if (!firstRequest) {
                $(getParentSvg()).prepend($(this));
            }
        });

        // Add and modify nodes
        $(elementHolder).children('svg').each(function () {

            var radius = $(this).children('circle').attr('r');
            var newX = $(this).attr('bx') - initial.x + wrapperBufferHorz - radius;
            var newY = $(this).attr('by') - initial.y + wrapperBufferVert - radius;

            $(this).attr({ x: newX, y: newY }, 0);

            $(this).click(function (event) {
                event.stopPropagation();
                if (!DRAGGING) {
                    var bn = $(this).attr('id');
                    var bnCode = bn.substring(2, 4);
                    if (bnCode == '18') {
                        window.location = '/b/system/' + bn;
                    } else if (bnCode == '2F') {
                    	window.location = '/b/company/' + bn;
                    }
                }
            });

            // Highlight nodes
            if ($(this).attr('hi') == true) {
                $(this).children('circle').setSvgAttr('stroke', lineHighlightColor);
            }

            // Add icon images (if circle is large enough)
            var iconURL = mapsUI.getSystemTypes()[ ($(this).attr('tp')) ][1];
            if (iconURL) {
                var d = 2 * $(this).find('circle').attr('r');
                $(svgImage(iconURL))
                    .attr({
                        x: 1,
                        y: 1,
                        width: d,
                        height: d
                    }).appendTo($(this));
            }

            // Spider nodes
            if ($(this).attr('sp') == true) {
                var nodeArray = new Array();
                var lineArray = new Array();
                spider($(this), nodeArray, lineArray);

                $.each(lineArray, function (i, line) {
                    line.data('stroke', lineHighlightColor);

                    //pop to highest in display stack
                    line.appendTo(getParentSvg());
                    line.show().attr('stroke', lineHighlightColor);
                    line.data('startsHidden', false);

                    // Marks that this line has already been formatted
                    line.data('visited', true);
                });

                $.each(nodeArray, function (i, node) {
                    node.find('circle').data('stroke', lineHighlightColor);
                    node.data('opacity', 1);

                    //pop to highest in display stack
                    node.appendTo(getParentSvg());
                    node.find('circle').attr({stroke: lineHighlightColor });

                    // Marks that this node has already been formatted
                    node.data('visited', true);
                });

                $(this).appendTo(getParentSvg());

                cleanUpArrayData(nodeArray);
                cleanUpArrayData(lineArray);
            }

            // Store data about the node
            if (!$(this).data('visited')) {
                // Set current color and opacity
                var currentColor = $(this).find('circle').attr('stroke');
                $(this).find('circle').data('stroke', currentColor);
            }

            // Setup hover event
            var nodeArray = new Array();
            var lineArray = new Array();
            var mousedNode;

            $(this).mouseenter(function () {
                mousedNode = $(this);
                var mousedNodeClone = mousedNode.clone();
                mousedNodeClone.appendTo(getParentSvg());
                mousedNode.hide();

                mousedNodeClone.click(function (event) {
                    event.stopPropagation();
                    var bn = $(this).attr('id');
                    var bnCode = bn.substring(2, 4);
                    if (bnCode == '18') {
                        window.location = '/b/system/' + bn;
                    } else if (bnCode == '2F') {
                    	window.location = '/b/company/' + bn;
                    }
                });

                // Cloned node handles hover display changes to ensure that events are not lost during switch
                mousedNodeClone.hover(function () {

                    mousedNodeClone.attr('class', 'svg-hover').find('circle').attr({ stroke: lineHoverColor });

                    // Add tooltip on hover
                    $('.tooltip.tooltip-map').remove();

                    var tooltip = $(
                        '<div class="tooltip tooltip-map fade in right">' +
                            '<div class="tooltip-arrow"></div>' +
                            '<div class="tooltip-inner">' + mousedNodeClone.attr('t') + '</div>' +
                            '</div>');
                    var pos = mousedNodeClone.position();
                    var r = parseFloat(mousedNodeClone.find('circle').attr('r'));   // Can't use height() and width()
                    tooltip.css({
                        top: pos.top + r - 70 + 'px',
                        left: pos.left + (2 * r) - 15 + 'px'
                    });
                    $('#map-wrapper').append(tooltip);

                    spider(mousedNodeClone, nodeArray, lineArray);

                    $.each(lineArray, function (i, line) {
                        var hoverLine = line.clone();
                        hoverLine.show().attr({ stroke: lineHoverColor});
                        hoverLine.attr('class', 'svg-hover');
                        hoverLine.insertBefore(mousedNodeClone);
                        line.hide();
                    });

                    $.each(nodeArray, function (i, node) {
                        var hoverNode = node.clone();
                        hoverNode.attr('class', 'svg-hover');
                        hoverNode.find('circle').attr({ stroke: lineHoverColor });
                        hoverNode.insertBefore(mousedNodeClone);
                        node.hide();
                    });
                }, function () {
                    $('.tooltip-map').remove();
                    $('.svg-hover').remove();

                    mousedNode.show();
                    $.each(nodeArray, function (i, node) {
                        node.show();
                    });
                    $.each(lineArray, function (i, line) {
                        if (!line.data('startsHidden') && zoom < 2) line.show();
                    });

                    cleanUpArrayData(nodeArray);
                    cleanUpArrayData(lineArray);
                    nodeArray = new Array();
                    lineArray = new Array();
                });
            });


            if (!firstRequest) {
                $(this).appendTo(getParentSvg());
            }
        });

    }

    function isLineHidden(line) {
        var display = line.css('display');
        if (display == "none") {
            return true;
        } else {
            return false;
        }
    }

    function makeBackgroundRequest(currentLocation, lastLocation, currentZoom) {
//        console.log("makeBackgroundRequet called. currentLocation="+parseInt(currentLocation.x) + ", " + parseInt(currentLocation.y)
//            +" lastLocation="+parseInt(lastLocation.x) + ", " + parseInt(lastLocation.y) +" currentZoom="+currentZoom);
        if (backgroundRequestEnabled) {
            backgroundRequestEnabled = false;
            currentRequest = currentRequest + 1;

            var url = '/b/mapresponse';
            if (options.overlayType != '') {
                url += "/" + options.overlayType + "/" + options.bn;
            }

            $.get(url, { currentLocation: currentLocation, lastLocation: lastLocation, currentRequest: currentRequest, zoom: currentZoom, svg: supportsSvg, mapWidth: containerWidth, mapHeight: containerHeight, horzBuffer: wrapperBufferHorz, vertBuffer: wrapperBufferVert, returnCenter: false }, function (data) {
                var response = data;
                var map = response.content;
                var overlayEnabled = response.overlayEnabled;

                if (supportsSvg) {
                    addMap(map, overlayEnabled);
                }
            });
        }
    }

    function spider(spiderNode, nodeArray, lineArray) {
        var id = spiderNode.attr('id');

        //console.log('spidering ' + id);

        if (!spiderNode.data('spidered')) {
            spiderNode.data('spidered', true);
            spiderNode.data('pushed', true);
            //nodeArray.push(spiderNode);

            var connections = $(getParentSvg()).find('line[sn=' + id + '], line[tn=' + id + ']');
            var spiderNodes = new Array();

            connections.each(function () {
                if (!$(this).data('pushed')) {
                    $(this).data('pushed', true);
                    lineArray.push($(this));

                    var targetBn = $(this).attr('tn');
                    var sourceBn = $(this).attr('sn');

                    if (targetBn != id) {
                        spiderNodes.push($('#' + targetBn));
                    }

                    if (sourceBn != id) {
                        spiderNodes.push($('#' + sourceBn));
                    }

                }

            });

            $.each(spiderNodes, function (i, node) {
                if (!node.data('pushed')) {
                    node.data('pushed', true);
                    nodeArray.push(node);
                }
            });

        }
    }

    function cleanUpArrayData(array) {
        $.each(array, function (i, nodeOrLine) {
            nodeOrLine.data('pushed', false);
            nodeOrLine.data('climbed', false);
            nodeOrLine.data('spidered', false);
        });
    }

    // Called by MapBase() ActionScript
    function showMapSpinner() {
        $spinner = $('.map-message.spinner');
        if (!$spinner) {
            $spinner = $('<div class="map-message spinner">Map Loading</div>');
            $spinner.hide();
            $('#map-wrapper').prepend($spinner);
        }
        $spinner.delay(500).fadeIn();
    };
    function hideMapSpinner() {
        $('.spinner').remove();
    };
    function showMapNoSystemsFound() {
        $('.map-message').remove();
        $message = $('<div class="map-message">No Applicable Systems</div>');
        $message.hide();
        $('.map, #map').prepend($message);
        $message.fadeIn();
    }

    //Enables mouseover on the genome map.
    //
    function handleMapEvents() {

        var origContainerHeight = mapContainer.height();
        var expanded = false;
        var expandLink = $('<a href="#" target="_self" class="expand"><span class="icon-fullscreen"></span></a>');
        mapContainer.prepend(expandLink);

        expandLink.click(function (e) {
            e.preventDefault();
            if (!expanded) {
                var maxSize = $(window).height() - 240;  // main header + space + title-header
                if (maxSize < 350) maxSize = 350;
                if (maxSize < origContainerHeight) maxSize = orgContainerHeight + 40;
                mapContainer.stop().animate({height: maxSize});
                expanded = true;

                // Make a request to fill in the map
                makeBackgroundRequest(current, current, zoom);
            }
            else {
                mapContainer.stop().animate({height: origContainerHeight});
                expanded = false;
            }
        });
    }
};


