/**
 * @param elem The element to hover over (jQuery object assumed)
 * @param content Text or an Elem to use within the tooltip
 */
ToolTip = function (elem, content, optionalClass) {

    this.tooltip = $('<div class="tooltip"/>');
    this.tooltip.html(content).hide();
    this.elem = elem;
    this.content = content;

    if (optionalClass) {
        this.tooltip.addClass(optionalClass);
    }

    // place in the body
    $('body').append(this.tooltip);

    var tip = this;

    elem.on('mouseenter', function(){
        tip.showToolTip();
    });
    elem.on('mouseleave', function(){;
        tip.hideToolTip();
    });
};

// override to set contents dynamically
ToolTip.prototype.setToolTipContents = function() {
    this.tooltip.html(this.content);
};

// override to add add'l processing
ToolTip.prototype.showToolTip = function() {
    this.setToolTipContents();
    this.positionToolTip();
    this.tooltip.show();
};

// override to add add'l processing
ToolTip.prototype.hideToolTip = function() {
    this.tooltip.hide();
};

// TODO: additional layout modes (N,E,S,W)
ToolTip.prototype.positionToolTip = function() {

    // position by elem
    var elemOffset = this.elem.offset(),
        elemHeight = this.elem.outerHeight(),
        top = elemOffset.top + elemHeight + 7,
        left = elemOffset.left - 10;

    this.tooltip.css({top:top, left:left});
};

