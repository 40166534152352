/*!
 * bit.base.jquery.truncatelist.js
 *
 * Truncates top-most lists within passed jQuery element(s) to only show MAX items
 * adding a toggle to expand / collapse immediately after the list
 *
 */

jQuery.fn.truncateList = function(options) {
	var defaults = {
		max: 7,
		expandMsg: "Show {n} More",
		collapseMsg: "Show Fewer"
	};
	var options = jQuery.extend(defaults, options);
	
	return this.each(function () {
		var isEditPage = $(".main-column, #main").hasClass("edit");

		if (!isEditPage && 
				!$(this).hasClass("skipTruncate") && 
				!$(this).hasClass("subConnections") &&				
				!$(this).hasClass("treeList")){
		
			var list = $(this).find("ul:first");
			var max = options.max;
			var expandMsg = options.expandMsg;
			var collapseMsg = options.collapseMsg;
										
			// get list items that push the list beyond max panel size
			var overflowItems = list.find("> li").slice(max);

			// hide the overflowing list items
			overflowItems.hide();		
	
			// set expand message
			var expandMsg = expandMsg.replace("\{n\}", overflowItems.length);
			
			if (overflowItems.length) {
								
				// create expand link
				var a = $("<a class='panel-expand-toggle'>" + expandMsg + "</a>");
			
				// toggle between hiding/showing list items based on user input
				a.toggle(function() {
					overflowItems.show();
					$(this).text(collapseMsg);
				}, function() {
					overflowItems.hide();
					$(this).text(expandMsg);
				});
				
				// link to expand hidden list items
				list.after(a);
				a.css('display', 'inline-block');
			}
		}
	});
};


