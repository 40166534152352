/*!
 * bit.base.jquery.detailtoggle.js
 *
 */

jQuery.fn.detailToggle = function(options) {
	var defaults = {
		detailSelector: ".assoc-path:not(table .assoc-path)",
		showMsg: "Detail",
		hideMsg: "Detail"
	};
	var options = jQuery.extend(defaults, options);
    /*
     * Assumption is that "this" is the element with class = panel or $(.panel).  There will be a different
     * element structure depending on if the panel is ajaxified or not.
     * 
     */
	return this.each(function () {
		var detailSelector = options.detailSelector;
		var showMsg = options.showMsg;
		var hideMsg = options.hideMsg;

		var isEditPage = $(".main-column").hasClass("edit");
		if (!isEditPage){
			// add show / hide links
            var panel = $(this);
			var details = panel.find(detailSelector);
			if (details.length) {
				
				// PAJ: moving to CSS switch rather than JS show/hide
				// This is quicker, and means that if items arrive inside 
				// the panel asynchronously, they will be displayed correctly
				// automatically.
				panel.addClass('hide-details');

                var child_elem = panel.find(".panel-body");
                var showDetailsLink = $("<a class='assoc-path-toggle'>" + showMsg + "</a>");
                child_elem.prepend(showDetailsLink);
                // add show / behaviors to toggle switches
                $(showDetailsLink).toggle(function() {
                	panel.removeClass("hide-details").addClass("show-details");
                    $(this).text(hideMsg);
                }, function() {
                	panel.removeClass("show-details").addClass("hide-details");
                    $(this).text(showMsg);
                });
            }
		}
	});
};


