/* A repository of small jQuery plugins */
// replaces our .andSelf() usage
// returns descendants that selector applies to
// as well as the element itself, if the selector applies
$.fn.findWithSelf = function( selector ) {
    return this.add(this.find(selector)).filter(selector)
};

// replaces option:[value="value] selector, removed in jQuery 1.9+
// helps DRY in querybuilder but could be used elsewhere
$.fn.filterByValue = function( value ) {
    return this.filter(function(){
        return $(this).val() === value;
    });
};

$.fn.filterByAttribute = function(attr, value) {
    return this.filter(function(){
        return $(this).attr(attr) === value;
    });
}

// uses native setAttribute method (jQuery automatically lowercases camelcase attr names!)
$.fn.setSvgAttr = function( attrName, value ) {
    return this.each(function(){
        this.setAttribute(attrName, value);
    });
};
