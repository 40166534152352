/*!
 * bit.base.jquery.modalwindow.js
 *
 */

jQuery.fn.topModalWindow = function () {
    $(this).modalWindow(true);
    return $(this);
};

jQuery.fn.modalWindow = function (useTopModal, fromWicket) {

    if (typeof useTopModal === "undefined") useTopModal = false;

    var overlayHolder = getOverlayHolder(useTopModal, fromWicket);
    overlayHolder.show();
    addOrUpdateScreens();
    prototype.setModalMaxHeights();
    // TODO: enable CSS3 transitions on show/hide (add/remove in/out classes)

    // close event - using overlayHolder is ajax-friendly
    overlayHolder.off('click', '.overlay-close');
    overlayHolder.on('click', '.overlay-close', function (e) {
        e.preventDefault();

        // put scrollbars back if this is the bottom overlay, or it's a top without a bottom
        if (!useTopModal || $(".overlay-holder:visible").length == 0 ) {
            $('body').css("overflow", "visible");
        }

        Object.keys(overlayHolder).map(function(objectKey, index) {
          if(!isNaN(objectKey)) {
            var value = overlayHolder[objectKey];
            while (value.firstChild) {
              value.removeChild(value.firstChild);
            }
          }
        });

        addOrUpdateScreens();
        $('body').scope().$broadcast('clearAllAlerts');
    });

    return $(this);

    function getOverlayHolder(topModalActive, fromWicket) {
        var overlayHolderSelector;
        if (fromWicket) {
            if (topModalActive) {
                overlayHolderSelector = '.wicket-overlay-top div.overlay-top-holder';
            } else {
                overlayHolderSelector = '.wicket-overlay div.overlay-holder';
            }
        } else {
            if (topModalActive) {
                overlayHolderSelector = '#overlayTopPhx div.overlay-top-holder';
            } else {
                overlayHolderSelector = '#overlayPhx div.overlay-holder';
            }
        }
        return $(overlayHolderSelector);
    }
};







